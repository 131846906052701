import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {OrganizaciaForm} from "./OrganizaciaForm";
import {Utils} from "../Utils";

export const OrganizaciaBrowse = (props: {}) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<OrganizaciaForm initValues={{version: 0}} onSaveOrCancel={onSaveOrCancel}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<OrganizaciaForm id={selectedRow.id} onSaveOrCancel={onSaveOrCancel}/>);
    }

    // tento event listener na DB operaciu neni pekny, trebalo by nejak sikovnejsie
    const onSaveOrCancel = (object: XObject | null, objectChange: OperationType) => {
        // funkcia na spracovanie zmeny sa setuje v ProjektDropdown
        Utils.getZmenaEntityOrganizacia()(object, objectChange);
        // z technickych dovodov
        if (objectChange !== OperationType.Remove) {
            (props as any).openForm(null); // vratime sa do browsu
        }
    }

    return (
        <XLazyDataTable entity="Organizacia" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} onRemoveRow={onSaveOrCancel}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="nazov" header="Názov" width="40rem"/>
        </XLazyDataTable>
    );
}
