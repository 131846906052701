import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {numberAsUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Utils} from "../Utils";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {SchvalitZrusitRequest, SchvalitZrusitResponse} from "../common/schvalit-zrusit-api";
import {TypPristupuEnum, XEnum} from "../common/enums";
import {Pobocka} from "../model/pobocka.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";

export interface SchvalitZrusitDialogState {
    opened: boolean;
    zoStavu?: XEnum;
    doStavu?: XEnum;
    operacia?: string;
}

// pouziva sa aj na uzatvorenie, nielen na schvalenie
export const SchvalitZrusitDialog = (props: {dialogState: SchvalitZrusitDialogState; onHideDialog: (ok: boolean) => void;}) => {

    const [datumOd, setDatumOd] = useState<Date | null>(null);
    const [datumDo, setDatumDo] = useState<Date | null>(null);
    const [pobocka, setPobocka] = useState<Pobocka | null>(null); // sem uklada XAutoCompleteBase vybratu hodnotu, ked jedneho dna dorobime multiselect, zmenime na pobockaList
    const [pocetZaznamov, setPocetZaznamov] = useState<number | null>(null);

    const [pobockaSuggestionList, setPobockaSuggestionList] = useState<Pobocka[]>([]);

    // kvazi member
    let pobockaError: string | undefined = undefined;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let pobockaFilter: XCustomFilter | undefined = Utils.filterCurrentOrganizacia();
        // ponukneme len tie pobocky pre ktore ma uzivatel pristup typu admin
        // kedze user spustil tuto funkciu, tak by mala existovat aspon jedna taka pobocka
        pobockaFilter = XUtils.filterAnd(pobockaFilter, Utils.filterCurrentPobocky("id", TypPristupuEnum.AdminPobocky));
        const pobockaList: Pobocka[] = await XUtils.fetchRows("Pobocka", pobockaFilter, "nazov");
        setPobockaSuggestionList(pobockaList);
    }

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        setDatumOd(null);
        setDatumDo(null);
        setPobocka(null);
        setPocetZaznamov(null);

        prepocitajPocetZaznamov(null, null, null);
    }

    // musime dat takto parametre lebo set metody (napr. setDatumOd) setuju hodnoty asynchronne (datumOd vracia staru hodnotu)
    const prepocitajPocetZaznamov = async (datumOd: Date | null, datumDo: Date | null, pobocka: any | null) => {
        const pocetZaznamov: number = await schvalitZrusit(true, datumOd, datumDo, pobocka);
        setPocetZaznamov(pocetZaznamov);
    }

    const onSchvalitZrusit = async () => {
        if (pobockaError) {
            alert("Nekorektná hodnota v položke Pobočka.");
            return;
        }
        const pocetZaznamov: number = await schvalitZrusit(false, datumOd, datumDo, pobocka);
        alert("Stav bol úspešne zmenený, počet zmenených záznamov: " + pocetZaznamov);

        props.onHideDialog(true);
    }

    const schvalitZrusit = async (lenPocetZaznamov: boolean, datumOd: Date | null, datumDo: Date | null, pobocka: any | null): Promise<number> => {
        let pocetZaznamov: number = 0;
        const currentProjektId: number | null = Utils.getCurrentProjektId();
        if (currentProjektId) {
            let pobockaIdList: number[];
            if (pobocka) {
                pobockaIdList = [pobocka.id];
            }
            else {
                // pobocka nie je vyplnena, nastavime vsetky povolene pobocky (uzivatel nemusi mat prava nastavovat stav na vsetkych pobockach)
                pobockaIdList = pobockaSuggestionList.map((value: Pobocka) => value.id);
            }
            const schvalitZrusitRequest: SchvalitZrusitRequest = {
                lenPocetZaznamov: lenPocetZaznamov,
                zoStavuId: props.dialogState.zoStavu!.id,
                doStavuId: props.dialogState.doStavu!.id,
                projektId: currentProjektId,
                pobockaIdList: pobockaIdList
            };
            if (datumOd instanceof Date) {
                schvalitZrusitRequest.datumOd = datumOd;
            }
            if (datumDo instanceof Date) {
                schvalitZrusitRequest.datumDo = datumDo;
            }
            const schvalitZrusitResponse: SchvalitZrusitResponse = await XUtils.fetchOne('schvalit-zrusit', schvalitZrusitRequest);
            pocetZaznamov = schvalitZrusitResponse.pocetZaznamov;
        }
        return pocetZaznamov;
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={() => props.onHideDialog(false)}
                header={`Zmena stavu: ${props.dialogState.zoStavu?.name} -> ${props.dialogState.doStavu?.name}`}>
            <div className="field grid">
                <label className="col-fixed" style={{width:'13rem'}}>Dátum dokladu od</label>
                <XCalendar value={datumOd} onChange={(value: Date | null) => {setDatumOd(value); prepocitajPocetZaznamov(value, datumOd, pobocka);}}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'13rem'}}>Dátum dokladu do</label>
                <XCalendar value={datumDo} onChange={(value: Date | null) => {setDatumDo(value); prepocitajPocetZaznamov(datumOd, value, pobocka);}}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'13rem'}}>Pobočka</label>
                <XAutoCompleteBase value={pobocka} suggestions={pobockaSuggestionList} field="nazov" idField="id"
                                   onChange={(object: Pobocka | null, objectChange: OperationType) => {setPobocka(object); prepocitajPocetZaznamov(datumOd, datumDo, object);}}
                                   onErrorChange={(error: (string | undefined)) => {pobockaError = error;}}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'13rem'}}>Počet záznamov</label>
                <InputText value={numberAsUI(pocetZaznamov ?? null)} readOnly={true}/>
            </div>
            <div className="flex justify-content-center">
                <XButton label={props.dialogState.operacia} onClick={onSchvalitZrusit}/>
            </div>
        </Dialog>
    );
}
