import React from "react";
import {XUserSkchForm} from "./XUserSkchForm";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {Utils} from "../Utils";
import {UserTypeEnum} from "../common/enums";

// upravena kopia XUserBrowse
export const XUserSkchBrowse = (props: {}) => {

    const onAddRow = () => {

        if (Utils.getCurrentOrganizacia() === null) {
            alert("Vyberte najprv organizáciu.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<XUserSkchForm initValues={{organizacia: Utils.getCurrentOrganizacia(), enabled: true, xUserSkchPristupList: [], version: 0}}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<XUserSkchForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> => {
        if (selectedRow.username === XUtils.getUsername()) {
            alert("Can not remove current user.");
            return false;
        }

        if (selectedRow.userType.code === UserTypeEnum.Admin.code && !Utils.isUserAdmin()) {
            alert("Vymazať záznam pre Typ používateľa Admin môže iba používateľ typu Admin.");
            return false;
        }

        if (window.confirm('Are you sure to remove the selected row?')) {
            await XUtils.removeRow("XUserSkch", selectedRow);
            return true;
        }
        return false;
    }

    return (
        <XLazyDataTable entity="XUserSkch" sortField="id" rows={30} onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                        customFilter={{where: "[organizacia] = :organizaciaId OR [userType.code] = :userTypeCode",
                                        params: {organizaciaId: Utils.getCurrentOrganizaciaId(), userTypeCode: UserTypeEnum.Admin.code}}}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="username" header="Username" width="17rem"/>
            <XLazyColumn field="name" header="Name" width="17rem"/>
            <XLazyColumn field="enabled" header="Enabled"/>
            <XLazyColumn field="userType.name" header="Typ používateľa" dropdownInFilter={true} width="17rem"/>
            <XLazyColumn field="organizacia.nazov" header="Organizácia" dropdownInFilter={true} width="8rem"/>
            <XLazyColumn field="xUserSkchPristupList.pobocka.nazov" header="Pobočky" width="12.5rem"/>
            <XLazyColumn field="xUserSkchPristupList.projekt.nazov" header="Projekty" width="15rem"/>
            <XLazyColumn field="xUserSkchPristupList.typPristupu.name" header="Typ prístupu" width="10rem"/>
        </XLazyDataTable>
    );
}
// zatial nepouzivane - prichystane do buducnosti
// ak by sme mali class komponenty, dal by sa pouzit decorator, pri formularoch mame class komponenty
XUtils.registerAppBrowse(<XUserSkchBrowse/>, "XUserSkch");
