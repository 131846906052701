import React from "react";
import {Form} from "../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XFieldChangeEvent, XTableFieldChangeEvent} from "@michalrakus/x-react-web-lib/lib/components/XFieldChangeEvent";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/lib/serverApi/FindParam";
import {XSearchButton} from "@michalrakus/x-react-web-lib/XSearchButton";
import {Utils} from "../Utils";
import {XDropdown} from "@michalrakus/x-react-web-lib/XDropdown";
import {TypBudgetLineEnum} from "../common/enums";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {numberFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {UtilsCommon} from "../common/UtilsCommon";
import {BudgetLineBrowse} from "./BudgetLineBrowse";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {TypBudgetLine} from "../model/typ-budget-line.entity";

@Form("BudgetLine")
export class BudgetLineForm extends XFormBaseModif {

    private kodFromEditStart: string | null = null;
    private budgetFromEditStart: number | null = null;

    constructor(props: any) {
        super(props);

        this.onChangeKodZaznamuParent = this.onChangeKodZaznamuParent.bind(this);
        this.parentFilter = this.parentFilter.bind(this);
        this.onChangeTypBudgetLine = this.onChangeTypBudgetLine.bind(this);

        // aby sme mali aj childList k dispozicii, pouzivame ho pri validacii
        // (nepouzivame frameworkovy save, preto sa childList neuklada do DB, ani nemame cascade na @OneToMany asociacii)
        this.addField("childList.id");
    }

    preInitForm(object: XObject, operationType: OperationType.Insert | OperationType.Update) {
        // ulozime si hodnotu fieldu kod zo zaciatku editacie (user moze hodnotu fieldu kod zmenit)
        this.kodFromEditStart = object.kod;
        this.budgetFromEditStart = object.budget;
    }

    onChangeKodZaznamuParent(e: XFieldChangeEvent) {
        let kod: string = '';
        if (e.object.parent) {
            kod += e.object.parent.kod + '.';
        }
        if (e.object.kodZaznamu) {
            kod += e.object.kodZaznamu;
        }
        e.object.kod = kod;
    }

    parentFilter(object: XObject): XCustomFilter | undefined {
        let filter: XCustomFilter | undefined = undefined;
        // ak nie je insert noveho zaznamu (mame this.kodFromEditStart), vyfiltrujeme vsetky child zaznamy (vcetne seba), aby nam zo stromu nevznikol cyklicky graf
        if (this.kodFromEditStart) {
            filter = {where: "[kod] NOT LIKE :kod", params: {"kod": `${this.kodFromEditStart}%`}};
        }
        return filter;
    }

    onChangeZmenitObdobieZProjektu(e: XFieldChangeEvent) {
        if (e.object.zmenitObdobieZProjektu) {
            // predplnime z projektu
            e.object.mesiacOd = e.object.projekt.mesiacOd;
            e.object.mesiacDo = e.object.projekt.mesiacDo;
        }
        else {
            // vymazeme (pre budget line sa pouzije obdobie z projektu)
            e.object.mesiacOd = null;
            e.object.mesiacDo = null;
        }
    }

    async onChangeTypBudgetLine(e: XFieldChangeEvent) {
        // ak user zmenil typ suctovy na koncovy, zmenil budget a vratil typ na suctovy, tak vratime povodnu (vypocitanu) hodnotu
        if (e.object.typBudgetLine && e.object.typBudgetLine.code === TypBudgetLineEnum.Suctovy) {
            e.object.budget = this.budgetFromEditStart;
        }

        if (e.object.typBudgetLine && e.object.typBudgetLine.code === TypBudgetLineEnum.KoncovyPobocky) {
            e.object.budget = 0.0;
            const pobockaList: any[] = await XUtils.fetchRows('Pobocka', Utils.filterCurrentOrganizacia(), 'nazov');
            e.object.budgetLinePobockaList = [];
            for (const pobocka of pobockaList) {
                this.onTableAddRow("budgetLinePobockaList", {pobocka: pobocka, budget: 0.0}, "id");
            }
        }
        else {
            // treba vyprazdnit budgetLinePobockaList, ak nie je prazdny
            if (e.object.budgetLinePobockaList.length > 0) {
                e.object.budgetLinePobockaList = [];
            }
        }

        if (e.object.typBudgetLine && e.object.typBudgetLine.code !== TypBudgetLineEnum.KoncovySpolocny && e.object.typBudgetLine.code !== TypBudgetLineEnum.KoncovyPobocky) {
            e.object.zmenitObdobieZProjektu = false;
            e.object.mesiacOd = null;
            e.object.mesiacDo = null;
        }
    }

    onChangePobockaBudget(e: XTableFieldChangeEvent) {
        let pobockaBudgetSpolu: number = 0;
        for (const budgetLinePobocka of e.object.budgetLinePobockaList) {
            let pobockaBudget: number | null;
            if (budgetLinePobocka.id !== e.tableRow.id) {
                pobockaBudget = numberFromModel(budgetLinePobocka.budget);
            }
            else {
                pobockaBudget = numberFromModel(e.tableRow.budget); // tu netreba numberFromModel (suma bola prave zapisana uzivatelom), ale pre istotu...
            }
            if (pobockaBudget) {
                pobockaBudgetSpolu += pobockaBudget;
            }
        }
        e.object.budget = UtilsCommon.numberRound(pobockaBudgetSpolu); // pre istotu zaokruhlime, sumovanim niekedy vznika chyba
    }

    readOnlyBudget(object: XObject): boolean {
        let readOnly: boolean = true;
        // len pri type koncovy-spolocny mozme zapisat budget, pri suctovom a koncovom pobockovom sa budget vypocitava = sucet child zaznamov
        if (object.typBudgetLine && object.typBudgetLine.code === TypBudgetLineEnum.KoncovySpolocny) {
            readOnly = false;
        }
        return readOnly;
    }

    // overrides method in XFormBase
    async validate(object: XObject): Promise<XErrors> {
        const errors: XErrors = {};
        if (object.zmenitObdobieZProjektu) {
            if (object.mesiacOd === null) {
                errors.mesiacOd = "Mesiac od musí byť vyplnený.";
            }
            if (object.mesiacDo === null) {
                errors.mesiacDo = "Mesiac do musí byť vyplnený.";
            }
        }
        // koncovy budget line mozme zadat len ak zaznam nema ziadne child zaznamy
        if (object.typBudgetLine
            && (object.typBudgetLine.code === TypBudgetLineEnum.KoncovySpolocny || object.typBudgetLine.code === TypBudgetLineEnum.KoncovyPobocky)
            && object.childList && object.childList.length > 0) {

            errors.typBudgetLine = `Typ "koncový" môže byť použitý len na zázname, ktorý nemá podzáznamy (nie je rodič pre iné záznamy).`;
        }
        return errors;
    }

    preSave(object: XObject) {
        super.preSave(object);

        // na suctovom zazname je budget readOnly a treba mu zapisat nejaku hodnotu
        if (object.typBudgetLine && object.typBudgetLine.code === TypBudgetLineEnum.Suctovy && !object.budget) {
            object.budget = 0;
        }
    }

    async saveRow(): Promise<any> {
        return XUtils.fetch('save-budget-line', this.state.object);
    }

    render() {
        const obdobieElemList: JSX.Element[] = [];
        if (this.state.object) {
            const typBudgetLine: TypBudgetLine = this.state.object.typBudgetLine;
            if (typBudgetLine && (typBudgetLine.code === TypBudgetLineEnum.KoncovySpolocny || typBudgetLine.code === TypBudgetLineEnum.KoncovyPobocky)) {
                obdobieElemList.push(<XCheckbox form={this} field="zmenitObdobieZProjektu" label="Zmeniť obdobie z projektu"
                                                labelStyle={{width: '18rem'}} onChange={this.onChangeZmenitObdobieZProjektu}/>);
                if (this.state.object.zmenitObdobieZProjektu) {
                    obdobieElemList.push(<XInputDate form={this} field="mesiacOd" label="Mesiac od"/>);
                    obdobieElemList.push(<XInputDate form={this} field="mesiacDo" label="Mesiac do"/>);
                }
                else {
                    // zobrazime obdobie z projektu (defaultne readOnly)
                    obdobieElemList.push(<XInputDate form={this} field="projekt.mesiacOd" label="Mesiac od"/>);
                    obdobieElemList.push(<XInputDate form={this} field="projekt.mesiacDo" label="Mesiac do"/>);
                }
            }
        }

        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="projekt.nazov" label="Projekt" size={30}/>
                        <XSearchButton form={this} assocField="parent" displayField="kod" searchBrowse={<BudgetLineBrowse/>} label="Rodič" onChange={this.onChangeKodZaznamuParent} filter={this.parentFilter}/>
                        <XInputText form={this} field="parent.nazov" label="Rodič - názov" size={50}/>
                        <XInputText form={this} field="kod" label="Kód" size={10} readOnly={true}/>
                        <XInputText form={this} field="kodZaznamu" label="Kód záznamu" size={5} onChange={this.onChangeKodZaznamuParent}/>
                        <XInputText form={this} field="nazov" label="Názov" size={50}/>
                    </div>
                    <div className="x-form-col">
                        <XDropdown form={this} assocField="typBudgetLine" displayField="name" sortField="id" label="Typ" onChange={this.onChangeTypBudgetLine}/>
                        {obdobieElemList}
                        <XInputDecimal form={this} field="budget" label="Budget" size={8} readOnly={this.readOnlyBudget}/>
                        <XFormDataTable2 form={this} assocField="budgetLinePobockaList" label="Pobočky - budget" showAddRemoveButtons={false} sortField="pobocka.nazov">
                            <XFormColumn field="pobocka.nazov" header="Pobočka" width="11rem"/>
                            <XFormColumn field="budget" header="Budget" width="7rem" onChange={this.onChangePobockaBudget}/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}

XUtils.registerAppForm(<BudgetLineForm/>, "BudgetLine");
