import React, {Component} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Utils} from "../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {Projekt} from "../model/projekt.entity";
import {XUserSkch} from "../model/x-user-skch.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XUserSkchPristup} from "../model/x-user-skch-pristup.entity";

export interface ProjektDropdownProps {
    onChangeProjekt: (projekt: any) => void;
}

export class ProjektDropdown extends Component<ProjektDropdownProps> {

    state: {
        options: Projekt[];
        currentProjekt: Projekt | null;
    };

    constructor(props: ProjektDropdownProps) {
        super(props);

        this.state = {
            options: [],
            currentProjekt: null
        };

        this.onChangeProjekt = this.onChangeProjekt.bind(this);
        this.zmenaEntityProjekt = this.zmenaEntityProjekt.bind(this);
    }

    onChangeProjekt(projekt: Projekt | null) {
        this.setState({currentProjekt: projekt});
        Utils.setCurrentProjekt(projekt);
        // refreshneme aktualny obsah
        this.props.onChangeProjekt(projekt);
    }

    componentDidMount() {
        this.findOptions();
        // zapiseme listener na zmenu do statickej premennej v Utils - listener sa zavola ak user spravi nejaku DB operaciu nad nejakym zaznamom Projekt
        Utils.setZmenaEntityProjekt(this.zmenaEntityProjekt);
    }

    async findOptions() {
        const xUserSkch: XUserSkch = Utils.getXUserSkch();
        let filter: XCustomFilter | undefined = Utils.filterCurrentOrganizacia();
        if (Utils.isUserPracovnikPobocky()) {
            // pracovnik pobocky musi mat projekt v zaznamoch XUserSkchPristup, zvysne 2 udaje pobocka a typPristupu su tu irelevantne
            filter = XUtils.filterAnd(filter, XUtils.filterIdIn("id", xUserSkch.xUserSkchPristupList.map((xUserSkchPristup: XUserSkchPristup) => xUserSkchPristup.projekt.id)));
        }
        let options: Projekt[] = await XUtils.fetchRows('Projekt', filter, 'nazov', ['mena.kod']);
        this.setState({options: options});
        if (options.length === 1) {
            this.onChangeProjekt(options[0]);
        }
        else {
            // TODO - z user profilu vybrat posledny projekt s ktorym user pracoval
            this.onChangeProjekt(null);
        }
    }

    async zmenaEntityProjekt(object: XObject | null, objectChange: OperationType) {
        if (objectChange !== OperationType.None) {
            // zmenili sa data v DB -> refresneme
            await this.findOptions();
            // ak bol zmeneny aktualny projekt, zapiseme ho do state.currentProjekt
            if (objectChange === OperationType.Update && (object && this.state.currentProjekt && object.id === this.state.currentProjekt.id)) {
                this.setState({currentProjekt: object});
                Utils.setCurrentProjekt(object as Projekt); // pre istotu (je to ina instancia ako ta v state.options ale je aktualna)
            }
        }
    }

    render() {
        // appendTo={document.body} appenduje overlay panel na element body - eliminuje "skakanie" formularu na mobile pri kliknuti na dropdown
        return (
            <Dropdown appendTo={document.body} id='projektDropdown' optionLabel='nazov' dataKey='id' value={this.state.currentProjekt} options={this.state.options}
                      onChange={(e: DropdownChangeEvent) => this.onChangeProjekt(e.value)}/>
        );
    }
}