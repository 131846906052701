// nahrada za vsetky jednoduche ciselnikove entity
export interface XEnum {
    id: number;
    code: string;
    name: string;
}


// zatial provizorne sem
// kedze typescript nepodporuje enum typu object literal (len number alebo string), pouzivame tuto konstrukciu
interface UserTypeEnumType {
    PracovnikPobocky: XEnum;
    PracovnikOrganizacieReadOnly: XEnum;
    AdminOrganizacie: XEnum;
    Admin: XEnum;
}

export const UserTypeEnum: UserTypeEnumType = {
    PracovnikPobocky: {id: 2, code: 'pracovnikPobocky', name: 'pracovník pobočky'},
    PracovnikOrganizacieReadOnly: {id: 3, code: 'pracovnikOrganizacieReadOnly', name: 'pracovník organizácie - len na čítanie'},
    AdminOrganizacie: {id: 4, code: 'adminOrganizacie', name: 'admin organizácie'},
    Admin: {id: 1, code: 'admin', name: 'admin aplikácie'}
} as const;

interface TypPristupuEnumType {
    ReadOnly: XEnum;
    ReadWrite: XEnum;
    AdminPobocky: XEnum;
}

// pozor! id-cka musia ist postupne - cim vyssie prava tym vyssie id - vyuziva sa to v metode Utils.userMaPristup
export const TypPristupuEnum: TypPristupuEnumType = {
    ReadOnly: {id: 1, code: 'readOnly', name: 'len na čítanie'},
    ReadWrite: {id: 2, code: 'readWrite', name: 'aj na zápis'},
    AdminPobocky: {id: 3, code: 'adminPobocky', name: 'admin pobočky'} // moze aj schvalovat (menit stav na vyssi ako vytvoreny a kompletny)
} as const;

interface StavVydavkuEnumType {
    Vytvoreny: XEnum;
    Kompletny: XEnum;
    Uzatvoreny: XEnum;
    Schvaleny: XEnum;
}

export const StavVydavkuEnum: StavVydavkuEnumType = {
    Vytvoreny: {id: 1, code: 'vytvoreny', name: 'vytvorený'},
    Kompletny: {id: 2, code: 'kompletny', name: 'kompletný'},
    Uzatvoreny: {id: 3, code: 'uzatvoreny', name: 'uzatvorený'},
    Schvaleny: {id: 4, code: 'schvaleny', name: 'schválený'}
} as const;

interface TypVydavkuEnumType {
    PokladnicnyDoklad: XEnum;
    Faktura: XEnum;
    Mzda: XEnum;
    PersonalnyDoklad: XEnum;
    Zmluva: XEnum;
    InyDoklad: XEnum;
}

export const TypVydavkuEnum: TypVydavkuEnumType = {
    PokladnicnyDoklad: {id: 1, code: 'pokladnicnyDoklad', name: 'pokladničný doklad'},
    Faktura: {id: 2, code: 'faktura', name: 'faktúra'},
    Mzda: {id: 3, code: 'mzda', name: 'mzda'},
    PersonalnyDoklad: {id: 4, code: 'personalnyDoklad', name: 'personálny doklad'},
    Zmluva: {id: 5, code: 'zmluva', name: 'zmluva'},
    InyDoklad: {id: 6, code: 'inyDoklad', name: 'iný doklad'}
} as const;

// zatial provizorne sem
export enum TypBudgetLineEnum {
    Suctovy = 'suctovy',
    KoncovySpolocny = 'koncovySpolocny',
    KoncovyPobocky = 'koncovyPobocky'
}

export enum TypBudgetLineId {
    SuctovyId = 1,
    KoncovySpolocnyId = 2,
    KoncovyPobockyId = 3
}

interface TypObdobiaEnumType {
    Datumy: XEnum;
    Mesacne: XEnum;
    Stvrtrocne: XEnum;
    Polrocne: XEnum;
    Rocne: XEnum;
}

export const TypObdobiaEnum: TypObdobiaEnumType = {
    Datumy: {id: 1, code: 'datumy', name: 'dátumy'},
    Mesacne: {id: 2, code: 'mesacne', name: 'mesiace'},
    Stvrtrocne: {id: 3, code: 'stvrtrocne', name: 'štvrťroky'},
    Polrocne: {id: 4, code: 'polrocne', name: 'polroky'},
    Rocne: {id: 5, code: 'rocne', name: 'roky'}
} as const;


export const menaEUR = {id: 1, kod: "EUR", nazov: "euro"};