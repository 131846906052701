import React from "react";
import {Password} from "primereact/password";
import {Form} from "../XLibItems";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XEnvVar, XReactAppAuth} from "@michalrakus/x-react-web-lib/XEnvVars";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XDropdown} from "@michalrakus/x-react-web-lib/XDropdown";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {UserTypeEnum} from "../common/enums";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {Utils} from "../Utils";
import {XUserSkch} from "../model/x-user-skch.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/lib/components/XFieldChangeEvent";

// upravena kopia XUserForm
@Form("XUserSkch")
export class XUserSkchForm extends XFormBaseModif {

    constructor(props: any) {
        super(props);

        this.state.usernameEnabledReadOnly = false;
        this.state.passwordNew = '';
        this.state.passwordNewConfirm = '';

        this.onClickSave = this.onClickSave.bind(this);
    }

    formReadOnly(object: XObject, field: string): boolean {
        if (object) {
            // zaznam pre uzivatela typu Admin moze editovat len Admin
            if (object.userType?.code === UserTypeEnum.Admin.code) {
                if (!Utils.isUserAdmin()) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    onChangeUserType(e: XFieldChangeEvent) {
        if (e.object.userType && (e.object.userType.code !== UserTypeEnum.PracovnikPobocky.code)) {
            // treba vyprazdnit xUserSkchPristupList, ak nie je prazdny (admin/adminOrganizacie/pracovnikOrganizacie maju pristup ku vsetkym projektom)
            if (e.object.xUserSkchPristupList.length > 0) {
                e.object.xUserSkchPristupList = [];
            }
        }
    }

    readOnlyPristupy(): boolean {
        let readOnly: boolean = true;
        // len pracovnik pobocky ma zadefinove pristupy
        if (this.state.object && this.state.object.userType?.code === UserTypeEnum.PracovnikPobocky.code) {
            readOnly = false;
        }
        return readOnly;
    }


    // overrides method in XFormBase
    async validate(xUserSkch: XUserSkch): Promise<XErrors> {
        const errors: XErrors = {};
        if (xUserSkch.userType
            && xUserSkch.userType.code === UserTypeEnum.PracovnikPobocky.code
            && xUserSkch.xUserSkchPristupList.length === 0) {
            errors.xUserSkchPristupList = `Typ používateľa Pracovník pobočky musí mať zadaný aspoň 1 záznam prístupu.`;
        }

        // ak je admin, tak dovolime max 1 zaznam
        if (xUserSkch.userType && xUserSkch.userType.code === UserTypeEnum.Admin.code) {
            let filter: XCustomFilter | undefined = {where: `[username] = :username AND [userType.code] = :adminCode`, params: {username: xUserSkch.username, adminCode: UserTypeEnum.Admin.code}};
            if (xUserSkch.id) {
                filter = XUtils.filterAnd(filter, {where: `[id] <> :id`, params: {id: xUserSkch.id}});
            }
            let xUserSkchList: XUserSkch[] = await XUtils.fetchRows('XUserSkch', filter);
            if (xUserSkchList.length > 0) {
                errors.username = `Používateľ ${xUserSkch.username} v roli Admin už existuje na zázname id = ${xUserSkchList[0].id}.`;
            }
        }
        return errors;
    }

    async onClickSave(): Promise<void> {

        if (!await this.validateSave()) {
            return;
        }

        // password is used only by local authorization
        if (XUtils.getEnvVarValue(XEnvVar.REACT_APP_AUTH) === XReactAppAuth.LOCAL) {
            if (this.isAddRow() && this.state.passwordNew === '') {
                alert("Password is required.");
                return;
            }

            if (this.state.passwordNew !== '' || this.state.passwordNewConfirm !== '') {

                // nedovolime tuto zmenit heslo aktualne prihlasenemu uzivatelovi, lebo by sme museli upravit aj token
                if (this.state.object.username === XUtils.getUsername()) {
                    alert("Please, change your password via option Administration -> Change password.");
                    return;
                }

                if (this.state.passwordNew !== this.state.passwordNewConfirm) {
                    alert("New password and confirmed new password are not equal.");
                    return;
                }

                // zapiseme nove heslo do objektu
                this.state.object.password = this.state.passwordNew;
            }
            else {
                // nemenime heslo (atribut s hodnotou undefined sa nezapise do DB)
                this.state.object.password = undefined;
            }
        }

        this.preSave(this.state.object);

        // zapise this.state.object do DB - samostatny servis koli hashovaniu password-u
        try {
            await XUtils.post('userSaveRow', {entity: this.getEntity(), object: this.state.object});
        }
        catch (e) {
            XUtils.showErrorMessage("Save row failed.", e);
            return; // zostavame vo formulari
        }
        (this.props as any).openForm(null); // save zbehol, ideme naspet do browsu
    }

    render() {
        let filterUserType: XCustomFilter | undefined = undefined;
        if (!Utils.isUserAdmin()) {
            filterUserType = {where: "[code] <> :userTypeAdminCode", params: {userTypeAdminCode: UserTypeEnum.Admin}};
        }

        // autoComplete="new-password" - bez tohto chrome predplna user/password, ak si user da ulozit user/password (pre danu url)
        let passwordElems: any[] = [];
        if (XUtils.getEnvVarValue(XEnvVar.REACT_APP_AUTH) === XReactAppAuth.LOCAL) {
            passwordElems = [
                <div className="field grid">
                    <label className="col-fixed" style={{width:'14rem'}}>New password</label>
                    <Password value={this.state.passwordNew} onChange={(e: any) => this.setState({passwordNew: e.target.value})} feedback={false} maxLength={64} size={20} autoComplete="new-password"/>
                </div>,
                <div className="field grid">
                    <label className="col-fixed" style={{width:'14rem', whiteSpace:'nowrap'}}>Confirm new password</label>
                    <Password value={this.state.passwordNewConfirm} onChange={(e: any) => this.setState({passwordNewConfirm: e.target.value})} feedback={false} maxLength={64} size={20} autoComplete="new-password"/>
                </div>
            ];
        }

        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true} labelStyle={{width:'14rem'}}/>
                        <XInputText form={this} field="username" label="Username" size={30} labelStyle={{width:'14rem'}} readOnly={this.state.usernameEnabledReadOnly}/>
                        <XInputText form={this} field="name" label="Name" size={30} labelStyle={{width:'14rem'}}/>
                        <XCheckbox form={this} field="enabled" label="Enabled" labelStyle={{width:'14rem'}} readOnly={this.state.usernameEnabledReadOnly}/>
                        <XDropdown form={this} assocField="userType" displayField="name" label="Typ používateľa" labelStyle={{width:'14rem'}} onChange={this.onChangeUserType} filter={filterUserType}/>
                        <XInputText form={this} field="organizacia.nazov" label="Organizácia" size={30} labelStyle={{width:'14rem'}}/>
                        <XFormDataTable2 form={this} assocField="xUserSkchPristupList" label="Prístupy" readOnly={this.readOnlyPristupy()}>
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormAutoCompleteColumn assocField="pobocka" displayField="nazov" header="Pobočka" filter={Utils.filterCurrentOrganizacia()} width="12.5rem"/>
                            <XFormAutoCompleteColumn assocField="projekt" displayField="nazov" header="Projekt" filter={Utils.filterCurrentOrganizacia()} width="20rem"/>
                            <XFormAutoCompleteColumn assocField="typPristupu" displayField="name" header="Typ prístupu" width="10rem"/>
                        </XFormDataTable2>
                        {passwordElems}
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true} labelStyle={{width:'14rem'}}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20} labelStyle={{width:'14rem'}}/>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
