import React from "react";
import {Component} from "react";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XAssoc, XEntity} from "@michalrakus/x-react-web-lib/lib/serverApi/XEntityMetadata";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {numberAsUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XButtonIconNarrow} from "@michalrakus/x-react-web-lib/XButtonIconNarrow";
import axios, {AxiosProgressEvent} from "axios";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XToken} from "@michalrakus/x-react-web-lib/XToken";
import {ProgressBar} from "primereact/progressbar";
import {XFileJsonField} from "@michalrakus/x-react-web-lib/lib/serverApi/XFileJsonField";
import {XUtilsMetadataCommon} from "@michalrakus/x-react-web-lib/XUtilsMetadataCommon";

interface XFile {
    id: number;
    name: string;
    size: number;
    pathName?: string;
}

export interface XInputFileListProps2 {
    form: XFormBase;
    assocField: string;
    label?: string;
    chooseLabel?: string;
    readOnly?: boolean;
    saveDest: "fileSystem" | "database";
    subdir?: string; // subdirectory, where to save uploaded file (for the case saveDest === "fileSystem")
    maxFileSize?: number; // maximum file size allowed in bytes
}

export class XInputFileList2 extends Component<XInputFileListProps2> {

    public static defaultProps = {
        saveDest: "fileSystem"
    };

    fileUploadRef: any;

    state: {
        uploadInProgress: boolean;
        progressState: number | undefined;
    };

    props: XInputFileListProps2;
    entity: string;
    idField: string;
    xFileField: string;

    constructor(props: XInputFileListProps2) {
        super(props);

        this.fileUploadRef = React.createRef();

        this.state = {
            uploadInProgress: false,
            progressState: undefined
        };

        this.props = props;
        const xEntityForm: XEntity = XUtilsMetadataCommon.getXEntity(props.form.getEntity());
        const xAssocToMany: XAssoc = XUtilsMetadataCommon.getXAssocToMany(xEntityForm, props.assocField);
        this.entity = xAssocToMany.entityName;
        const xEntity = XUtilsMetadataCommon.getXEntity(this.entity);
        this.idField = xEntity.idField;
        this.xFileField = XUtilsMetadataCommon.getXAssocToOneByAssocEntity(xEntity, 'XFile').name;

        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);

        //this.onProgress = this.onProgress.bind(this);

        const fieldFilename = `${props.assocField}.${this.xFileField}.filename`;
        props.form.addField(fieldFilename);
    }

    async uploadHandler(event: FileUploadHandlerEvent) {
        //const file = event.files[0];

        const endpoint: string = this.props.saveDest === 'fileSystem' ? 'x-upload-file-into-file-system' : 'x-upload-file-into-db';

        for (const file of event.files) {
            // skontrolujeme velkost - robime to tuto, lebo ked nastavime maxFileSize na komponente FileUpload, tak prilis velky subor sem do handlera ani neposle
            if (this.props.maxFileSize !== undefined && file.size > this.props.maxFileSize) {
                alert(xLocaleOption('fileUploadSizeToBig', {fileName: file.name, fileSize: XInputFileList2.sizeInMB(file.size), maxFileSize: XInputFileList2.sizeInMB(this.props.maxFileSize)}))
                continue; // ideme na dalsi subor
            }
            // uploadneme subor na server, insertne sa tam zaznam XFile a tento insertnuty zaznam pride sem a zapiseme ho do zoznamu form.object.<assocField>
            const jsonFieldValue: XFileJsonField = {
                filename: file.name,
                subdir: this.props.subdir,
                modifDate: new Date(),
                modifXUser: XUtils.getXToken()?.xUser.id
            }

            let xFile: XFile;
            // POVODNY KOD:
            // try {
            //     xFile = await XUtils.fetchFile(endpoint, jsonFieldValue, file);
            // }
            // catch (e) {
            //     XUtils.showErrorMessage(xLocaleOption('fileUploadFailed', {fileName: file.name}), e);
            //     this.fileUploadRef.current.clear(); // vyprazdnime hidden input, nech moze user znova zadat subory
            //     return; // prerusime upload tohto a dalsich suborov
            // }

            this.setState({uploadInProgress: true, progressState: 0});

            const formData = new FormData();
            formData.append(
                "jsonField",
                XUtilsCommon.objectAsJSON(jsonFieldValue)
            );
            formData.append(
                "fileField",
                file,
                file.name
            );
            let xToken: XToken | null = XUtils.getXToken();
            if (xToken === null) {
                xToken = XUtils.xTokenPublic; // ak nikto nie je prihlaseny, posleme public token
            }
            const headers = {'Authorization': `Bearer ${xToken.accessToken}`};
            let response: {data: any; status: any;};
            try {
                response = await axios.postForm(XUtils.getXBackendUrl() + endpoint, formData, {headers: headers, onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        //console.log(progressEvent);
                        if (progressEvent.progress) {
                            this.setState({progressState: progressEvent.progress * 100});
                        }
                    }});
            }
            catch (error) {
                XUtils.showErrorMessage(xLocaleOption('fileUploadFailed', {fileName: file.name}), error);
                this.fileUploadRef.current.clear(); // vyprazdnime hidden input, nech moze user znova zadat subory
                this.setState({uploadInProgress: false, progressState: undefined});
                return; // prerusime upload tohto a dalsich suborov
            }

            this.setState({uploadInProgress: false, progressState: undefined});

            //console.log(`data = ${JSON.stringify(response.data)}`);
            //console.log(`status = ${response.status}`);

            xFile = response.data as XFile;

            const newFileItem: any = {};
            newFileItem[this.xFileField] = xFile;
            this.props.form.onTableAddRow(this.props.assocField, newFileItem, this.idField);
        }

        // vymaze zaznamy v event.files (hidden input type="file"), sposobi ze tlacitko "+Pridat" otvori dialog na vyber suborov
        this.fileUploadRef.current.clear();
    }

    static sizeInMB(size: number): string {
        const sizeInMB = size / (10 ** 6);
        return numberAsUI(sizeInMB, 2) + ' MB'; // zobrazime 2 desatinky
    }

    async onDownloadFile(xFile: XFile) {
        let response;
        try {
            response = await XUtils.fetchBasicJson('x-download-file', {xFileId: xFile.id});
        }
        catch (e) {
            XUtils.showErrorMessage(xLocaleOption('fileDownloadFailed'), e);
            return;
        }
        const fileName = xFile.name;
        // let respJson = await response.json(); - konvertuje do json objektu
        let respBlob = await response.blob();

        // download blob-u (download by mal fungovat asynchronne a "stream-ovo" v spolupraci so servrom)
        let url = window.URL.createObjectURL(respBlob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    async onRemoveFile(fileItem: any) {
        // poznamka: nemozme zmazat zaznam na backend-e, lebo ak user ukonci editaciu formulara tlacitkom Cancel (alebo odide uplne prec),
        // tak musime mat v databaze zachovany povodny stav dat/suborov
        this.props.form.onTableRemoveRow(this.props.assocField, fileItem);
    }

    // onProgress() {
    //     let progress: number = this.state.progressState ?? 0;
    //     progress += 10;
    //     this.setState({uploadInProgress: true, progressState: progress});
    // }

    render() {
        const label = this.props.label ?? this.props.assocField;
        const readOnly = this.props.form.formReadOnlyBase(this.props.assocField) || (this.props.readOnly ?? false);

        const object: XObject | null = this.props.form.state.object;
        const fileItemList: any[] = object !== null ? object[this.props.assocField] : [];

        let elemList: any[] = [];
        for (const fileItem of fileItemList) {
            const xFile: XFile = fileItem[this.xFileField];
            // p-inputgroup uklada child elementy do riadku (display:flex)
            // TODO - pouzit XButtonIconSmall pre button na mazanie - problem je ze tam nevieme (narychlo) dat class m-1
            elemList.push(
                <div key={fileItem[this.idField].toString()} className="p-inputgroup p-mb-1">
                    <XButton label={xFile.name} onClick={() => this.onDownloadFile(xFile)}/>
                    <XButtonIconNarrow icon="pi pi-times" onClick={() => this.onRemoveFile(fileItem)} disabled={readOnly}/>
                </div>
            );
        }

        if (this.state.uploadInProgress) {
            elemList.push(
                <ProgressBar value={this.state.progressState} showValue={false}/>
            );
        }

        // vrchny div uklada child elementy pod seba (standardny display:block), zarovnane su dolava
        return (
            <div className="x-inputfilelist">
                <label>{label}</label>
                {elemList}
                <FileUpload ref={this.fileUploadRef} mode="basic" multiple auto customUpload uploadHandler={this.uploadHandler}
                            chooseLabel={this.props.chooseLabel ?? xLocaleOption('addRow')} className="m-1" disabled={readOnly || this.state.uploadInProgress}/>
                {/*<XButton label='Progress' onClick={() => this.onProgress()}/>*/}
            </div>
        );
    }
}