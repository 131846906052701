import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XOnSaveOrCancelProp} from "@michalrakus/x-react-web-lib/XFormBase";
import {TypPristupuEnum, UserTypeEnum, XEnum} from "./common/enums";
import {XUserSkch} from "./model/x-user-skch.entity";
import {Organizacia} from "./model/organizacia.entity";
import {Projekt} from "./model/projekt.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XUserSkchPristup} from "./model/x-user-skch-pristup.entity";
import {Pobocka} from "./model/pobocka.entity";

export class Utils {

    // enablovane zaznamy aktualneho uzivatela (nastavi sa po login-e)
    // jeden z nich je nastaveny v XUtils.xToken.xUser ako aktualny pouzivatel
    private static currentXUserSkchList: XUserSkch[] = [];

    private static currentOrganizacia: Organizacia | null = null;

    private static zmenaEntityOrganizacia: XOnSaveOrCancelProp;

    private static currentProjekt: Projekt | null = null;

    private static zmenaEntityProjekt: XOnSaveOrCancelProp;

    static getXUserSkch(): XUserSkch {
        return XUtils.getXToken()?.xUser;
    }

    static isUserAdmin(): boolean {
        return Utils.isUserType(UserTypeEnum.Admin);
    }

    static isUserAdminOrganizacie(): boolean {
        return Utils.isUserType(UserTypeEnum.AdminOrganizacie);
    }

    static isUserPracovnikPobocky(): boolean {
        return Utils.isUserType(UserTypeEnum.PracovnikPobocky);
    }

    static isUserAdminOrganizacieOrAdmin(): boolean {
        return Utils.isUserAdminOrganizacie() || Utils.isUserAdmin();
    }

    static isUserType(userType: XEnum): boolean {
        const userTypeCode: string = Utils.getXUserSkch()?.userType?.code;
        if (userTypeCode) {
            return userTypeCode === userType.code;
        }
        return false;
    }

    static userMaPristup(pobocka: Pobocka | null | undefined, typPristupu: XEnum): boolean {

        const xUserSkch: XUserSkch = Utils.getXUserSkch();
        if (xUserSkch.userType.code === UserTypeEnum.Admin.code || xUserSkch.userType.code === UserTypeEnum.AdminOrganizacie.code) {
            return true; // admini maju vzdy max pristup
        }
        if (xUserSkch.userType.code === UserTypeEnum.PracovnikOrganizacieReadOnly.code) {
            return typPristupu.code === TypPristupuEnum.ReadOnly.code; // ma pristup iba na citanie
        }
        if (xUserSkch.userType.code === UserTypeEnum.PracovnikPobocky.code) {
            // prava na pobockovej urovni
            const currentProjekt: Projekt | null = Utils.getCurrentProjekt();
            if (!currentProjekt) {
                return false; // nema vybraty projekt v comboboxe v menu
            }
            // musi mat prava na aktualny projekt a pobocku (ak je zadana) a musi mat pozadovany pristup alebo vyssi (id-cka zaznamov TypPristupu to zohladnuju)
            // toto by sa dalo zoptimalizovat vytvorenim nejakej Map ale asi to aj tak ma minimalny vplyv na performance
            return xUserSkch.xUserSkchPristupList.some(
                (value: XUserSkchPristup) =>
                    value.projekt.id === currentProjekt.id && (pobocka === null || pobocka === undefined || value.pobocka.id === pobocka.id) && value.typPristupu.id >= typPristupu.id
            );
        }
        throw `Neimplementovane zistovanie pristupu pre xUserSkch.userType.code = ${xUserSkch.userType.code}`;
    }

    static setCurrentXUserSkchList(xUserSkchList: XUserSkch[]) {
        Utils.currentXUserSkchList = xUserSkchList;
    }

    static getCurrentXUserSkchList(): XUserSkch[] {
        return Utils.currentXUserSkchList;
    }

    static setCurrentOrganizacia(organizacia: Organizacia) {
        Utils.currentOrganizacia = organizacia;
    }

    static getCurrentOrganizacia(): Organizacia | null {
        return Utils.currentOrganizacia;
    }

    static getCurrentOrganizaciaId(): number | null {
        return Utils.getCurrentOrganizacia() ? Utils.getCurrentOrganizacia()!.id : null;
    }

    static setZmenaEntityOrganizacia(zmenaEntityOrganizacia: XOnSaveOrCancelProp) {
        Utils.zmenaEntityOrganizacia = zmenaEntityOrganizacia;
    }

    static getZmenaEntityOrganizacia(): XOnSaveOrCancelProp {
        return Utils.zmenaEntityOrganizacia;
    }

    static filterCurrentOrganizacia(): XCustomFilter {
        return {where: "[organizacia] = :organizaciaId", params: {organizaciaId: Utils.getCurrentOrganizaciaId()}};
    }

    // vrati filter pre pobocky zavisly od aktualneho usera (vracia undefined, ak ma user pravo na vsetky pobocky (Admin, Admin Organizacie, ...)
    // idToAdd je specialny parameter koli tomu aby sme vedeli nacitat pobocku zobrazeny v readOnly formulari
    static filterCurrentPobocky(idField: string, typPristupu: XEnum, idToAdd?: number): XCustomFilter | undefined {
        let filter: XCustomFilter | undefined
        const xUserSkch: XUserSkch = Utils.getXUserSkch();
        if (xUserSkch.userType.code === UserTypeEnum.PracovnikOrganizacieReadOnly.code) {
            // ak ziadame pristup na citanie, tak ok, inac vratime [id] IN (0)
            if (typPristupu.code !== TypPristupuEnum.ReadOnly.code) {
                filter = XUtils.filterIdIn(idField, idToAdd ? [idToAdd] : []);
            }
        }
        else if (xUserSkch.userType.code === UserTypeEnum.PracovnikPobocky.code) {
            // pracovnikom obmedzime pobocky
            let xUserSkchPristupList: XUserSkchPristup[] = [];
            const currentProjekt: Projekt | null = Utils.getCurrentProjekt();
            if (currentProjekt) {
                xUserSkchPristupList = xUserSkch.xUserSkchPristupList.filter(
                    (value: XUserSkchPristup) => value.projekt.id === currentProjekt.id && value.typPristupu.id >= typPristupu.id
                );
            }
            // ak je xUserSkchPristupList prazdny, vytvori podmienku [id] IN (0) (ak nemame ani idToAdd)
            const idList: number[] = xUserSkchPristupList.map((value: XUserSkchPristup) => value.pobocka.id);
            if (idToAdd) {
                idList.push(idToAdd);
            }
            filter = XUtils.filterIdIn(idField, idList);
        }
        // else - xUserSkch.userType je bud Admin alebo AdminOrganizacie - ma plne prava na vsetky pobocky, ziadny filter nepridavame
        return filter;
    }

    static setCurrentProjekt(projekt: Projekt | null) {
        Utils.currentProjekt = projekt;
    }

    static getCurrentProjekt(): Projekt | null {
        return Utils.currentProjekt;
    }

    static getCurrentProjektId(): number | null {
        return Utils.getCurrentProjekt() ? Utils.getCurrentProjekt()!.id : null;
    }

    static setZmenaEntityProjekt(zmenaEntityProjekt: XOnSaveOrCancelProp) {
        Utils.zmenaEntityProjekt = zmenaEntityProjekt;
    }

    static getZmenaEntityProjekt(): XOnSaveOrCancelProp {
        return Utils.zmenaEntityProjekt;
    }

    // zatial takto natvrdo
    private static schema: string = "grosh";

    static getSchema(): string {
        return Utils.schema;
    }
}
