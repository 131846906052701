import React, {useEffect, useState} from "react";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {
    DataTable,
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableSelectionSingleChangeEvent
} from "primereact/datatable";
import {Column} from "primereact/column";
import {BudgetReportLine, BudgetReportRequest, BudgetReportResponse} from "../common/budget-report-api";
import {Utils} from "../Utils";
import {
    dateFromUI,
    numberAsUI,
    stringAsUI,
    XDateScale
} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {TypBudgetLineEnum, TypObdobiaEnum, TypPristupuEnum, XEnum} from "../common/enums";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {Divider} from "primereact/divider";
import {VydavokBrowse} from "./VydavokBrowse";
import {FilterMatchMode} from "primereact/api";
import {createFilterItemForBudgetLine} from "./VydavokBrowseBudgetLineFilter";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {Pobocka} from "../model/pobocka.entity";
import {BudgetReportFilter, DateOption, FilterValues} from "./BudgetReportFilter";

export const BudgetReport = (props: {}) => {

    const [value, setValue] = useState<BudgetReportResponse>({budgetReportLineList: [], sumySpolu: {budget: null, budgetPrePobocku: false, cerpanie: null, zostatok: null, cerpaniePercento: null}});
    const [vypisatPobocky, setVypisatPobocky] = useState<boolean>(true);
    const [vypisatMesiace, setVypisatMesiace] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);

    // sem si ulozime hodnoty z filtra, ktore boli pouzite pri filtrovani
    // zaroven su to aj inicializacne hodnoty filtra
    const [filterValues, setFilterValues] = useState<FilterValues>({
        selectedPobockaList: [],
        typObdobia: TypObdobiaEnum.Mesacne,
        datumOd: null,
        datumDo: null,
        selectedDateList: []
    });

    const [pobockaSuggestionList, setPobockaSuggestionList] = useState<Pobocka[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let pobockaFilter: XCustomFilter | undefined = Utils.filterCurrentOrganizacia();
        pobockaFilter = XUtils.filterAnd(pobockaFilter, Utils.filterCurrentPobocky("id", TypPristupuEnum.ReadOnly));
        const pobockaSuggestionList: Pobocka[] = await XUtils.fetchRows("Pobocka", pobockaFilter, "nazov");
        setPobockaSuggestionList(pobockaSuggestionList);

        // do createBudgetReportRequest musime posielat priamo pobocka, pobockaSuggestionList, lebo "state membre" maju este staru hodnotu
        loadBudgetReport(filterValues, pobockaSuggestionList);
    }

    const loadBudgetReport = async (filterValues: FilterValues, pobockaSuggestionList: Pobocka[]) => {
        const currentProjektId: number | null = Utils.getCurrentProjektId();
        if (currentProjektId) {
            setLoading(true);
            const budgetReportResponse: BudgetReportResponse = await XUtils.fetchOne('budget-report', createBudgetReportRequest(filterValues, pobockaSuggestionList));
            setValue(budgetReportResponse);
            // ak mame vybratu prave jednu pobocku alebo mame povolenu len jednu pobocku, nema velky zmysel vypisovat pobocky
            if ((filterValues.selectedPobockaList.length === 1 || pobockaSuggestionList.length === 1)
                && (!clenitPodlaMesiacov(filterValues) || !vypisatMesiace)) {
                setVypisatPobocky(false);
            }
            // ak mame vybraty prave jeden mesiac, nema velky zmysel vypisovat mesiace
            if (clenitPodlaMesiacov(filterValues) && filterValues.selectedDateList.length === 1) {
                setVypisatMesiace(false);
            }
            // odlozime aplikovane hodnoty
            setFilterValues(filterValues);

            setLoading(false);
        }
    }

    const filtrovat = async (filterValues: FilterValues) => {
        loadBudgetReport(filterValues, pobockaSuggestionList);
    }

    const createBudgetReportRequest = (filterValues: FilterValues, pobockaSuggestionList: Pobocka[]) => {
        let pobockaIdList: number[];
        if (filterValues.selectedPobockaList.length > 0) {
            pobockaIdList = filterValues.selectedPobockaList.map((value: Pobocka) => value.id);
        }
        else {
            // ani jedna pobocka nie je vyselectovana, nastavime vsetky povolene pobocky (uzivatel nemusi mat prava nastavovat stav na vsetkych pobockach)
            pobockaIdList = pobockaSuggestionList.map((value: Pobocka) => value.id);
        }
        const budgetReportRequest: BudgetReportRequest = {
            projektId: Utils.getCurrentProjektId()!,
            pobockaIdList: pobockaIdList,
            clenitPodlaPobociek: true,
            typObdobia: filterValues.typObdobia,
            vypisatPobocky: vypisatPobocky,
            vypisatMesiace: vypisatMesiace
        };
        if (filterValues.typObdobia === TypObdobiaEnum.Datumy) {
            if (filterValues.datumOd !== null) {
                budgetReportRequest.datumOd = filterValues.datumOd;
            }
            if (filterValues.datumDo !== null) {
                budgetReportRequest.datumDo = filterValues.datumDo;
            }
        }
        else if (filterValues.typObdobia === TypObdobiaEnum.Mesacne) {
            budgetReportRequest.mesiacList = filterValues.selectedDateList.map((value: DateOption) => value.date);
            budgetReportRequest.clenitPodlaMesiacov = clenitPodlaMesiacov(filterValues);
        }
        return budgetReportRequest;
    }

    const zobrazitVydavky = async (rowData: BudgetReportLine) => {

        // podla toho ako sa da, nieco z filtra (a z vykliknuteho zaznamu) dame do uzivatelskeho filtra a nieco do custom filtra

        let budgetLine: any = await XUtils.fetchById('BudgetLine', ["typBudgetLine.code"], rowData.id);
        let filterItem: DataTableFilterMetaData & {valueUI: any[];} = await createFilterItemForBudgetLine([budgetLine]);

        const filters: DataTableFilterMeta = {};
        let customFilter: XCustomFilter | undefined = undefined;

        if (!filterValues) {
            throw "Unexpected error - filterValues is undefined";
        }

        // datumy/mesiace
        if (filterValues.typObdobia === TypObdobiaEnum.Datumy) {
            filters["datumVystavenia"] = {value: [filterValues.datumOd, filterValues.datumDo], matchMode: FilterMatchMode.BETWEEN};
        }
        else if (filterValues.typObdobia === TypObdobiaEnum.Mesacne) {
            // mohli by sme mesiac/mesiace konvertovat do datumOd/datumDo ale zatial dame natvrdo do customFilter
            if (rowData.mesiac) {
                // user klikol na row ktory obsahuje mesiac
                customFilter = XUtils.filterAnd(customFilter, {
                    where: "date_trunc('month', [datumVystavenia])::DATE = :mesiac",
                    params: {mesiac: dateFromUI(rowData.mesiac, XDateScale.Month)} // rowData.mesiac je vo formate MM.YYYY
                });
            }
            else if (filterValues.selectedDateList.length > 0) {
                customFilter = XUtils.filterAnd(customFilter, {
                    where: "date_trunc('month', [datumVystavenia])::DATE IN (:...mesiacList)",
                    params: {mesiacList: filterValues.selectedDateList.map((value: DateOption) => value.date)}
                });
            }
        }

        // pobocka
        let pobockaNazov: string | undefined = undefined;
        if (rowData.pobocka) {
            // user klikol na row ktory obsahuje pobocku
            pobockaNazov = rowData.pobocka;
        }
        else if (filterValues.selectedPobockaList.length === 1) {
            pobockaNazov = filterValues.selectedPobockaList[0].nazov;
        }
        if (pobockaNazov) {
            filters["pobocka.nazov"] = {value: pobockaNazov, matchMode: FilterMatchMode.EQUALS};
        }
        else {
            if (filterValues.selectedPobockaList.length > 0) {
                customFilter = XUtils.filterAnd(customFilter, {
                    where: "[pobocka] IN (:...pobockaIdList)", // TypeORM zameni "pobocka" za pobocka_id
                    params: {pobockaIdList: filterValues.selectedPobockaList.map((value: Pobocka) => value.id)}
                });
            }
        }

        // budget line
        filters["vydavokBudgetLineList.budgetLine"] = filterItem as DataTableFilterMetaData;

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VydavokBrowse filters={filters} customFilter={customFilter}/>);
    }

    const rowClassName = (rowData: BudgetReportLine) => {
        return {
            'bg-gray-300 font-semibold': rowData.typBudgetLineCode === TypBudgetLineEnum.Suctovy
        };
    };

    const kodNazovTemplate = (value: string | null, typBudgetLineCode: TypBudgetLineEnum | "pobocka" | "pobocka-mesiac") => {
        return <div className={typBudgetLineCode !== "pobocka" && typBudgetLineCode !== "pobocka-mesiac" ? "text-gray-900" : "text-gray-300"}>{stringAsUI(value)}</div>;
    };

    const pobockaTemplate = (value: string | null, typBudgetLineCode: TypBudgetLineEnum | "pobocka" | "pobocka-mesiac") => {
        return <div className={typBudgetLineCode !== "pobocka-mesiac" ? "text-gray-900" : "text-gray-300"}>{stringAsUI(value)}</div>;
    };

    const typBudgetuTemplate = (typBudgetLineCode: TypBudgetLineEnum | "pobocka" | "pobocka-mesiac") => {
        let typBudgetu: string = "";
        if (typBudgetLineCode === TypBudgetLineEnum.KoncovySpolocny) {
            typBudgetu = "spoločný";
        }
        else if (typBudgetLineCode === TypBudgetLineEnum.KoncovyPobocky) {
            typBudgetu = "pobočky";
        }
        return typBudgetu;
    };

    const budgetTemplate = (budget: number | null, budgetPrePobocku: boolean) => {
        return <div className={budgetPrePobocku ? "text-blue-500" : "text-gray-900"}>{numberAsUI(budget)}</div>;
    };

    const readGreenTemplate = (value: number | null) => {
        return <div className={value !== null && value < 0 ? "text-red-500" : "text-green-500"}>{numberAsUI(value)}</div>;
    };

    const readGreenTemplatePercento = (value: number | null) => {
        return <div className={value !== null && value > 100 ? "text-red-500" : "text-green-500"}>{numberAsUI(value)}</div>;
    };

    const exportDoExcelu = () => {
        XUtils.openExcelReport("budget-report-export", createBudgetReportRequest(filterValues, pobockaSuggestionList), "budget-report");
    };

    const clenitPodlaMesiacov = (filterValues: FilterValues): boolean => {
        return filterValues.typObdobia === TypObdobiaEnum.Mesacne && filterValues.selectedDateList.length > 0;
    }

    let budgetReportLineList: BudgetReportLine[] = value.budgetReportLineList;
    if (!vypisatPobocky) {
        budgetReportLineList = budgetReportLineList.filter((budgetReportLine: BudgetReportLine) => budgetReportLine.typBudgetLineCode !== "pobocka" && budgetReportLine.typBudgetLineCode !== "pobocka-mesiac");
    }
    else if (!vypisatMesiace) {
        budgetReportLineList = budgetReportLineList.filter((budgetReportLine: BudgetReportLine) => budgetReportLine.typBudgetLineCode !== "pobocka-mesiac");
    }

    return (
        <div>
            <BudgetReportFilter initFilterValues={filterValues} pobockaSuggestionList={pobockaSuggestionList} filtrovat={filtrovat}/>
            <div className="flex justify-content-center align-items-center" style={{height: '0.5rem'}}>
                <Divider/>
            </div>
            <div className="flex justify-content-center align-items-center">
                <label className="m-2">Vypísať pobočky</label>
                <Checkbox checked={vypisatPobocky} onChange={(e: CheckboxChangeEvent) => setVypisatPobocky(e.checked ?? false)}/>
                {clenitPodlaMesiacov(filterValues) ?
                    [<label className="m-2">Vypísať mesiace</label>,
                    <Checkbox checked={vypisatMesiace} onChange={(e: CheckboxChangeEvent) => setVypisatMesiace(e.checked ?? false)}/>] : null
                }
            </div>
            <div className="flex justify-content-center">
                <DataTable value={budgetReportLineList} loading={loading} className="p-datatable-sm x-lazy-datatable"
                           rowClassName={rowClassName} scrollable scrollHeight="calc(100vh - 15.5rem)" filterDisplay="row"
                           selectionMode="single" selection={selectedRow} onSelectionChange={(event: DataTableSelectionSingleChangeEvent<BudgetReportLine[]>) => setSelectedRow(event.value)}>
                    <Column field="kod" header="Kód" sortable={true} body={(rowData: BudgetReportLine) => kodNazovTemplate(rowData.kod, rowData.typBudgetLineCode)}/>
                    <Column field="nazov" header="Názov" sortable={true} body={(rowData: BudgetReportLine) => kodNazovTemplate(rowData.nazov, rowData.typBudgetLineCode)}/>
                    <Column field="typBudgetLineCode" header="Typ budgetu" sortable={true} body={(rowData: BudgetReportLine) => typBudgetuTemplate(rowData.typBudgetLineCode)}/>
                    <Column field="pobocka" header="Pobočka" sortable={true} body={(rowData: BudgetReportLine) => pobockaTemplate(rowData.pobocka, rowData.typBudgetLineCode)} footer={!clenitPodlaMesiacov(filterValues) ? "Spolu" : undefined}/>
                    {clenitPodlaMesiacov(filterValues) ? <Column field="mesiac" header="Mesiac" sortable={true} footer="Spolu"/> : null}
                    <Column field="sumy.budget" header="Budget" headerStyle={{width:'8rem'}} align="right" body={(rowData: BudgetReportLine) => budgetTemplate(rowData.sumy.budget, rowData.sumy.budgetPrePobocku)} sortable={true} footer={budgetTemplate(value.sumySpolu.budget, value.sumySpolu.budgetPrePobocku)}/>
                    <Column field="sumy.cerpanie" header="Čerpanie" headerStyle={{width:'8rem'}} align="right" body={(rowData: BudgetReportLine) => numberAsUI(rowData.sumy.cerpanie)} sortable={true} footer={numberAsUI(value.sumySpolu.cerpanie)}/>
                    <Column field="sumy.zostatok" header="Zostatok" headerStyle={{width:'8rem'}} align="right" body={(rowData: BudgetReportLine) => readGreenTemplate(rowData.sumy.zostatok)} sortable={true} footer={readGreenTemplate(value.sumySpolu.zostatok)}/>
                    <Column field="sumy.cerpaniePercento" header="Čerpanie %" headerStyle={{width:'8rem'}} align="right" body={(rowData: BudgetReportLine) => readGreenTemplatePercento(rowData.sumy.cerpaniePercento)} sortable={true} footer={readGreenTemplatePercento(value.sumySpolu.cerpaniePercento)}/>
                    <Column body={(rowData: BudgetReportLine) => <XButtonIconMedium icon="pi pi-search" onClick={() => zobrazitVydavky(rowData)}/>}/>
                </DataTable>
            </div>
            <div className="flex justify-content-center text-blue-500" style={{fontSize: '80%'}}>
                Poznámka: modré budgety sa vzťahujú k danej pobočke, čierne budgety platia pre všetky pobočky
            </div>
            <div className="flex justify-content-center">
                <XButton icon="pi pi-file-export" label={xLocaleOption('exportRows')} onClick={exportDoExcelu}/>
            </div>
        </div>
    );
}
