import React, {useRef, useState} from "react";
import {Menubar} from "primereact/menubar";
import {XFormNavigator3} from "@michalrakus/x-react-web-lib/XFormNavigator3";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XHolder1, XHolder2} from "@michalrakus/x-react-web-lib/XHolders";
import {VydavokBrowse} from "./forms/VydavokBrowse";
import {ProjektBrowse} from "./forms/ProjektBrowse";
import {PobockaBrowse} from "./forms/PobockaBrowse";
import {BudgetLineBrowse} from "./forms/BudgetLineBrowse";
import {ProjektDropdown} from "./forms/ProjektDropdown";
import {XUserSkchBrowse} from "./forms/XUserSkchBrowse";
import {MenuItem} from "primereact/menuitem";
import {Utils} from "./Utils";
import {BudgetReport} from "./forms/BudgetReport";
import {OrganizaciaBrowse} from "./forms/OrganizaciaBrowse";
import {OrganizaciaDropdown} from "./forms/OrganizaciaDropdown";
import {Organizacia} from "./model/organizacia.entity";
import {MenaBrowse} from "./forms/MenaBrowse";

// TODO - v buducnosti presunut do XReactWebLib
export const XMenu = (props: {defaultFormElement?: any; logout: () => void;}) => {

    const [rootFormElement, setRootFormElement] = useState<any>(props.defaultFormElement);
    const [renderHolder1, setRenderHolder1] = useState<boolean>(true);

    // pouzivame ref aby sme vedeli zavolat metodu na komponente ProjektDropdown (nemusime potom vytahovat state premenne sem do XMenu)
    const projektDropdownRef: React.RefObject<ProjektDropdown> = useRef(null);

    const items: MenuItem[] = [
        {
            label:'Výdavky',
            command: () => {openForm(<VydavokBrowse/>);},
            disabled: Utils.getCurrentProjekt() === null
        },
        {
            label:'Report',
            command: () => {openForm(<BudgetReport/>);},
            disabled: Utils.getCurrentProjekt() === null
        },
        {
            label:'Administrácia',
            disabled: !Utils.isUserAdminOrganizacieOrAdmin(),
            items:[
                {label:'Organizácie', command: () => {openForm(<OrganizaciaBrowse/>);}, disabled: !Utils.isUserAdmin()},
                {label:'Meny', command: () => {openForm(<MenaBrowse/>);}},
                {label:'Používatelia', command: () => {openForm(<XUserSkchBrowse/>);}, disabled: Utils.getCurrentOrganizacia() === null},
                //{label:'Change password', command: () => {openForm(<XChangePasswordForm setXToken={props.setXToken}/>);}}
                {label:'Projekty', command: () => {openForm(<ProjektBrowse/>);}, disabled: Utils.getCurrentOrganizacia() === null},
                {label:'Pobočky', command: () => {openForm(<PobockaBrowse/>);}, disabled: Utils.getCurrentOrganizacia() === null},
                {label:'Budget lines', command: () => {openForm(<BudgetLineBrowse/>);}, disabled: Utils.getCurrentProjekt() === null}
            ]
        },
        {
            label:'Odhlásenie',
            icon:'pi pi-fw pi-power-off',
            command: props.logout
        }
    ];

    const openForm = (newFormElement: any) => {
        setRootFormElement(newFormElement);
        // we want refresh the whole element tree (call constructors of class components, call componentDidMount methods to refresh data, ...)
        // change of Holder1 to Holder2 and back will cause mounting of new component tree
        // without this, changing from <XBrowse entity="Brand"/> to <XBrowse entity="Car"/> will not work (component tree will not change)
        setRenderHolder1(!renderHolder1);
    }

    const onChangeOrganizacia = (organizacia: Organizacia) => {
        // zmenila sa organizacia, refreshneme dropdown s projektami
        // findOptions zmeni projekt, ak nevie na aky zmenit, tak zmeni na null a zaoval sa onChangeProjekt, ktory refreshne data
        projektDropdownRef!.current!.findOptions();
    }

    const onChangeProjekt = (projekt: any) => {
        if (rootFormElement === null && Utils.getCurrentProjekt() !== null) {
            setRootFormElement(<VydavokBrowse/>);
        }
        // otvorime browse, ktory mame ulozeny v rootFormElement (posledne otvoreny browse)
        setRenderHolder1(!renderHolder1);
    }

    /* dropdown ide nalavo, backend + user ide napravo (pomocou marginLeft: 'auto') - este boli potrebne zmeny v App.css */
    const end: any = (
        <div className="flex" style={{width: '100%'}}>
            <div className="m-3"/>
            <OrganizaciaDropdown onChangeOrganizacia={onChangeOrganizacia}/>
            <div className="m-1"/>
            <ProjektDropdown ref={projektDropdownRef} onChangeProjekt={onChangeProjekt}/>
            <div className="flex align-content-center" style={{marginLeft: 'auto'}}>
                <div style={{padding: '0.5rem'}}>Backend: {XUtils.getXBackendUrl()}</div>
                <div style={{padding: '0.5rem'}}>Používateľ: {/*XUtils.getUsername()*/XUtils.getXToken()?.xUser?.name}</div>
                {XUtils.getXToken()?.xUser?.pobocka ? <div style={{padding: '0.5rem'}}>Pobočka: {XUtils.getXToken()?.xUser?.pobocka.nazov}</div> : null}
            </div>
        </div>
    );

    // little workaround - see remark in function openForm
    let holderWithNavigator;
    if (renderHolder1) {
        holderWithNavigator = <XHolder1 element={<XFormNavigator3 rootFormElement={rootFormElement}/>}/>;
    }
    else {
        holderWithNavigator = <XHolder2 element={<XFormNavigator3 rootFormElement={rootFormElement}/>}/>;
    }

    return (
        <div>
            <Menubar id="menuId" model={items} end={end} className="mb-1"/>
            {holderWithNavigator}
        </div>
    );
}
