import React from "react";
import {Form} from "../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {Utils} from "../Utils";

@Form("Pobocka")
export class PobockaForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.onClickAddRow = this.onClickAddRow.bind(this);
    }

    createNewObject(): XObject {
        return {organizacia: Utils.getCurrentOrganizacia(), pobockaMenaList: [], pobockaStrediskoList: [], version: 0};
    }

    onClickAddRow() {
        this.onTableAddRow("pobockaMenaList", {predplnit: false});
    };

    render() {
        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputText form={this} field="nazov" label="Názov" size={50}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XFormDataTable2 form={this} assocField="pobockaMenaList" label="Meny" sortField={this.isAddRow() ? undefined : "poradie"} onClickAddRow={this.onClickAddRow}>
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormAutoCompleteColumn assocField="mena" displayField="kod" header="Mena" width="6rem"/>
                            <XFormColumn field="predplnit" header="Predplniť"/>
                            <XFormColumn field="poradie" header="Poradie" width="6rem"/>
                        </XFormDataTable2>
                        <XFormDataTable2 form={this} assocField="pobockaStrediskoList" label="Strediská" sortField={this.isAddRow() ? undefined : "poradie"}>
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormColumn field="nazov" header="Názov" width="16rem"/>
                            <XFormColumn field="poradie" header="Poradie" width="6rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}

XUtils.registerAppForm(<PobockaForm/>, "Pobocka");
