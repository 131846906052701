import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import React from "react";
import {Form} from "../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {OperationType, XUtils, XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";
import {XFormFooter, xSaveButtonId} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XFormAutoCompleteColumn, XFormColumn, XFormCustomColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XFieldChangeEvent, XTableFieldChangeEvent} from "@michalrakus/x-react-web-lib/lib/components/XFieldChangeEvent";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {
    dateAsUI,
    dateFromModel,
    numberAsUI,
    numberFromModel,
    XDateScale
} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Utils} from "../Utils";
import {StavVydavkuEnum, TypBudgetLineId, TypPristupuEnum, TypVydavkuEnum} from "../common/enums";
import {UtilsCommon} from "../common/UtilsCommon";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XInputFileList2} from "./XInputFileList2";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {BudgetLine} from "../model/budget-line.entity";
import {Pobocka} from "../model/pobocka.entity";
import {PobockaStredisko} from "../model/pobocka-stredisko.entity";
import {PobockaMena} from "../model/pobocka-mena.entity";
import {Mena} from "../model/mena.entity";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XInputDecimalBase} from "@michalrakus/x-react-web-lib/XInputDecimalBase";
import {ColumnBodyOptions} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {BudgetLinePobocka} from "../model/budget-line-pobocka.entity";
import {StavVydavku} from "../model/stav-vydavku.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

@Form("Vydavok")
export class VydavokForm extends XFormBaseModif {

    private datumVystaveniaFromEditStart: Date | null = null;

    constructor(props: any) {
        super(props);

        // najoinujeme aj strediska na pobocke (v pripade ak existuju, tak zobrazime atribut Stredisko)
        //this.addField("pobocka.pobockaStrediskoList.nazov");
        // najoinujeme stredisko a kod (komponenty sa vytvaraju asi az neskor, po nacitani pobociek)
        this.addField("stredisko.nazov");
        this.addField("mena.kod");
        // pre validaciu potrebujeme mat nacitany aj budgetLinePobockaList s pobockami
        this.addField("vydavokBudgetLineList.budgetLine.budgetLinePobockaList.pobocka.id");

        this.readOnlyPobocka = this.readOnlyPobocka.bind(this);
        this.onChangePobocka = this.onChangePobocka.bind(this);
        this.onChangeHrubaSumaVMene = this.onChangeHrubaSumaVMene.bind(this);
        this.onChangeHrubaSumaVMeneFix = this.onChangeHrubaSumaVMeneFix.bind(this);
        this.onChangePercento = this.onChangePercento.bind(this);
        this.onChangePercentoFix = this.onChangePercentoFix.bind(this);
        this.onChangeSumaVMene = this.onChangeSumaVMene.bind(this);
        this.onChangeSumaVMeneFixZPercenta = this.onChangeSumaVMeneFixZPercenta.bind(this);
        this.onChangeSumaVMeneFixZBL = this.onChangeSumaVMeneFixZBL.bind(this);
        this.onChangeMena = this.onChangeMena.bind(this);
        this.onChangeKurz = this.onChangeKurz.bind(this);
        this.onChangeBLSumaVMene = this.onChangeBLSumaVMene.bind(this);
    }

    async preInitForm(object: XObject, operationType: OperationType.Insert | OperationType.Update) {

        // vezmeme pobocku (ak je uz zadana) a nacitame akokeby "deep" pobocku (aj s detailami) a nastavime ju do objektu
        // ak je pobocka editovatelna (admin ma take pravo), tak nacitame zoznam "deep" pobociek pre autocomplete
        // toto nacitanie pobocky/pobociek by sme mohli vykonat aj inde (napr. pri nacitavani XUserSkch) ale chceme to mat na jednom mieste (jeden service)
        // POZNAMKA: nepouzivame componentDidMount(), lebo v nej nemame k dispozicii prave nacitany this.state.object (set metoda ho nastavuje asynchronne)
        // (preInitForm je v podstate rozsirenie/doplnenie XFormBase.componentDidMount())

        // preratame full-time sumu pri mzdach - je to vypocitavany atribut, existuje len na frontende
        for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
            this.preratajBLHrubaSumaVMene(object, vydavokBudgetLine); // full-time suma pri mzdach
        }

        let pobocka: Pobocka | null = object.pobocka; // aktualne zvolena pobocka na vydavku - pre pracovnika vzdy zadana, pre admina pri inserte moze byt null
        let pobockaFilter: XCustomFilter | undefined = Utils.filterCurrentOrganizacia();
        // ponukneme len tie pobocky pre ktore ma uzivatel pristup na zapis
        pobockaFilter = XUtils.filterAnd(pobockaFilter, Utils.filterCurrentPobocky("id", TypPristupuEnum.ReadWrite, pobocka ? pobocka.id : undefined));
        const pobockaList: Pobocka[] = await XUtils.fetchRows("Pobocka", pobockaFilter, "nazov", ["pobockaMenaList.mena.kod", "pobockaStrediskoList.nazov"]);
        if (!pobocka) {
            // sme v inserte, mali by sme mat aspon jednu pobocku v pobockaList
            if (pobockaList.length === 0) {
                throw `Neočakávaná chyba: object.pobocka = null (zavolaný insert) a zoznam pobockaList je prázdny.`;
            }
            // ak mame prave jednu pobocku, tak ju rovno predplnime, nech ju nemusi vyplnat uzivatel
            if (pobockaList.length === 1) {
                pobocka = pobockaList[0];
            }
        }
        if (pobocka) {
            // zapiseme si do objektu tuto deep pobocku, ktora uz obsahuje aj pobockaMenaList a pobockaStrediskoList
            pobocka = pobockaList.filter((pobockaItem: Pobocka) => pobockaItem.id === pobocka!.id)[0];
            object.pobocka = pobocka;
            this.nastavDefaultMenu(object);
        }
        // pobockaList musime zapisat do state, inac sa zmena neprejavi
        this.setState({pobockaList: pobockaList});

        // nacitame ponukane budget lines (uplne po spravnosti by asi malo ist do componentDidMount() ale neni to az take podstatne)
        await this.loadBudgetLineSuggestions(pobockaList);

        // ulozime si hodnotu fieldu datumVystavenia zo zaciatku editacie (pri zmene skontrolujeme ci datum nepadne do uzatvoreneho obdobia)
        this.datumVystaveniaFromEditStart = dateFromModel(object.datumVystavenia);
    }

    async loadBudgetLineSuggestions(pobockaList: Pobocka[]) {
        // pre vyber budget line dame podmienku - musi platit budget > 0, ak sa jedna o uzivatela pobocky a mame definovane budgety po pobockach, tak budget pre prislusnu pobocku musi byt > 0
        // 12.2.2024 - rusime podmienku [budget] > 0 pre koncovy spolocny budget line - chcu mat budget line na ktory budu vesat 0-ove vydavky s nejakymi prilohami (pre BL koncovy pobocky podmienku > 0 nechame)
        const pobockaWhere: string = `EXISTS (SELECT 1 FROM ${Utils.getSchema()}.budget_line_pobocka blp WHERE blp.budget_line_id = [id] AND blp.pobocka_id IN (:...pobockaIdList) AND blp.budget > 0)`;
        const where: string = `[projekt] = ${Utils.getCurrentProjektId()} AND (([typBudgetLine] = ${TypBudgetLineId.KoncovySpolocnyId}) OR ([typBudgetLine] = ${TypBudgetLineId.KoncovyPobockyId} AND ${pobockaWhere}))`;
        const filter: XCustomFilter = {where: where, params: {pobockaIdList: pobockaList.map((value: Pobocka) => value.id)}};
        // pre validaciu potrebujeme mat nacitany aj budgetLinePobockaList s pobockami
        const budgetLineList: BudgetLine[] = await XUtils.fetchRows("BudgetLine", filter, "kod", ["budgetLinePobockaList.pobocka.id"]);
        this.setState({budgetLineList: budgetLineList});
    }

    formReadOnly(object: XObject, field: string): boolean {
        if (object) {
            // editovat moze len ak ma read-write pristup
            if (Utils.userMaPristup(object.pobocka, TypPristupuEnum.ReadWrite)) {
                // editovatelny je len v stave vytvoreny alebo kompletny (stavy ktore su k dispozicii pracovnikovi pobocky)
                if (object.stavVydavku.code === StavVydavkuEnum.Vytvoreny.code || object.stavVydavku.code === StavVydavkuEnum.Kompletny.code) {
                    return false;
                }
                // v inych stavoch mozme menit len stav v pripade ak sme admin - k tomu treba aj save button dostupny
                else if (Utils.userMaPristup(object.pobocka, TypPristupuEnum.AdminPobocky)) {
                    if (field === "stavVydavku" || field === xSaveButtonId) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    readOnlyPobocka(object: XObject): boolean {
        return this.state.pobockaList ? this.state.pobockaList.length <= 1 : true;
    }

    onChangePobocka(e: XFieldChangeEvent) {
        e.object.stredisko = null;
        e.object.mena = null; // nastavi novu default menu, ak je to mozne
        this.nastavDefaultMenu(e.object);
        // ak nema uzivatel na novonastavenej pobocke pravo nastavit vyssi stav a vyssi stav bol nastaveny, tak nastavime stav na null
        if (!Utils.userMaPristup(e.object.pobocka, TypPristupuEnum.AdminPobocky)) {
            const stavVydavku: StavVydavku | null = e.object.stavVydavku;
            if (stavVydavku !== null && stavVydavku.code !== StavVydavkuEnum.Vytvoreny.code && stavVydavku.code !== StavVydavkuEnum.Kompletny.code) {
                e.object.stavVydavku = null;
            }
        }
    }

    // ********** hrubaSumaVMene **************

    onChangeHrubaSumaVMene(e: XFieldChangeEvent) {
        e.object.hrubaSumaVMeneFix = true;
        this.hrubaSumaVMeneZmenena(e.object);

        // dame aj sem primarne zmenu sumy a az sekundarne zmenu percenta (tak je to aj pri atribute sumaVMene)
        if (!e.object.sumaVMeneFixZPercenta) {
            this.preratajSumaVMeneZPercenta(e.object);
        }
        else {
            this.preratajPercento(e.object);
        }
    }

    onChangeHrubaSumaVMeneFix(e: XFieldChangeEvent) {
        // ak uzivatel odskrtol fix, tak preratame hodnotu
        if (!e.object.hrubaSumaVMeneFix) {
            this.preratajHrubaSumaVMene(e.object);
        }
    }

    preratajHrubaSumaVMene(object: XObject) {
        if (!object.hrubaSumaVMeneFix) {
            object.hrubaSumaVMene = this.vyratajHrubaSumaVMene(object);
            this.hrubaSumaVMeneZmenena(object);
        }
    }

    vyratajHrubaSumaVMene(object: XObject): number | null {
        let hrubaSumaVMene: number | null = null;
        const percento: number | null = numberFromModel(object.percento);
        const sumaVMene: number | null = numberFromModel(object.sumaVMene);
        if (percento !== null && percento !== 0 && sumaVMene !== null) {
            hrubaSumaVMene = UtilsCommon.numberRound(sumaVMene / (percento / 100));
        }
        return hrubaSumaVMene;
    }

    preratajHrubaSuma(object: XObject) {
        // hrubu sumu by sme este mohli ratat ako suma / percento ale pouzijeme kurz, nech je to presne to iste ako hrubaSumaVMene ak je mena = EUR
        object.hrubaSuma = VydavokForm.preratajCezKurz(object.hrubaSumaVMene, object.kurz);
    }

    // onChangeHrubaSumaPercento(e: XFieldChangeEvent) {
    //     let suma: number | null = null;
    //     const hrubaSuma: number | null = numberFromModel(e.object.hrubaSuma);
    //     const percento: number | null = numberFromModel(e.object.percento);
    //     if (hrubaSuma != null && percento != null) {
    //         const sumaNotRounded = hrubaSuma * percento / 100;
    //         suma = Math.round(sumaNotRounded * 100) / 100;
    //     }
    //     e.object.suma = suma;
    //
    //     this.onChangeSumaBase(e.object);
    // }

    hrubaSumaVMeneZmenena(object: XObject) {
        this.preratajHrubaSuma(object); // v eur
    }

    // ********** percento **************

    onChangePercento(e: XFieldChangeEvent) {
        e.object.percentoFix = true;
        this.percentoZmenene(e.object);
        // prioritne zmenime hrubu sumu (Spis najprv vplna BL a hruba suma je pren nepodstatna)
        if (!e.object.hrubaSumaVMeneFix) {
            this.preratajHrubaSumaVMene(e.object);
        }
        else {
            // sekundarne zmenime sumu
            this.preratajSumaVMeneZPercenta(e.object);
        }
    }

    onChangePercentoFix(e: XFieldChangeEvent) {
        // ak uzivatel odskrtol fix, tak preratame hodnotu
        if (!e.object.percentoFix) {
            this.preratajPercento(e.object);
        }
    }

    preratajPercento(object: XObject) {
        if (!object.percentoFix) {
            object.percento = this.vyratajPercento(object);
            this.percentoZmenene(object);
        }
    }

    vyratajPercento(object: XObject): number | null {
        const sumaVMene: number | null = numberFromModel(object.sumaVMene);
        const hrubaSumaVMene: number | null = numberFromModel(object.hrubaSumaVMene);
        return UtilsCommon.computePercent(sumaVMene, hrubaSumaVMene);
    }

    percentoZmenene(object: XObject) {
        // zmeni sa full-time info, pre jednoduchost preratame cely BL
        this.preratajBLRiadky(object);
    }

    // ********** sumaVMene **************

    onChangeSumaVMene(e: XFieldChangeEvent) {
        e.object.sumaVMeneFixZPercenta = true;
        e.object.sumaVMeneFixZBL = true;
        // suma sa rata ako sucet sum BL
        //this.preratajSuma(e.object);
        // preratame hrubu sumu aj BL (ak mame len 1 riadok)
        this.sumaVMeneZmenena(e.object, true, true);
    }

    onChangeSumaVMeneFixZPercenta(e: XFieldChangeEvent) {
        // ak uzivatel odskrtol fix, tak preratame hodnotu
        if (!e.object.sumaVMeneFixZPercenta) {
            this.preratajSumaVMeneZPercenta(e.object);
        }
    }

    onChangeSumaVMeneFixZBL(e: XFieldChangeEvent) {
        // ak uzivatel odskrtol fix, tak preratame hodnotu
        if (!e.object.sumaVMeneFixZBL) {
            this.preratajSumaVMeneZBL(e.object);
        }
    }

    preratajSumaVMeneZPercenta(object: XObject) {
        if (!object.sumaVMeneFixZPercenta) {
            object.sumaVMene = this.vyratajSumaVMeneZPercenta(object);
            // preratame aj BL, ak ma len 1 riadok
            this.sumaVMeneZmenena(object, false, true); // nechceme preratat hrubu sumu/percento, odtial sme prisli
        }
    }

    vyratajSumaVMeneZPercenta(object: XObject): number | null {
        let sumaVMene: number | null = null;
        const hrubaSumaVMene: number | null = numberFromModel(object.hrubaSumaVMene);
        const percento: number | null = numberFromModel(object.percento);
        if (hrubaSumaVMene !== null && percento !== null) {
            sumaVMene = UtilsCommon.numberRound(hrubaSumaVMene * (percento / 100));
        }
        return sumaVMene;
    }

    preratajSumaVMeneZBL(object: XObject) {
        if (!object.sumaVMeneFixZBL) {
            object.sumaVMene = this.vyratajSumaVMeneZBL(object);
            // preratame aj hrubu sumu
            this.sumaVMeneZmenena(object, true, false); // nechceme preratat BL (ak ma 1 riadok), odtial sme prisli
        }
    }

    vyratajSumaVMeneZBL(object: XObject): number {
        let sumaVMene: number = 0;
        for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
            const blSumaVMene: number | null = numberFromModel(vydavokBudgetLine.sumaVMene);
            if (blSumaVMene !== null) {
                sumaVMene += blSumaVMene;
            }
        }
        sumaVMene = UtilsCommon.numberRound(sumaVMene)!; // vrati vzdy ne-null
        return sumaVMene;
    }

    // pomocna metodka
    sumaVMeneZmenena(object: XObject, preratajSmerPercento: boolean, preratajBL: boolean) {
        if (preratajSmerPercento) {
            // preratame prioritne hrubu sumu, ak sa neda tak percento
            if (!object.hrubaSumaVMeneFix) {
                this.preratajHrubaSumaVMene(object);
            }
            else {
                this.preratajPercento(object);
            }
        }

        if (preratajBL) {
            this.preratajBLAk1Riadok(object);
        }

        this.preratajBLRiadky(object);
    }

    // onChangeSuma(e: XFieldChangeEvent) {
    //
    //     const suma: number | null = numberFromModel(e.object.suma); // tu netreba numberFromModel (suma bola prave zapisana uzivatelom), ale pre istotu...
    //     const hrubaSuma: number | null = numberFromModel(e.object.hrubaSuma);
    //     e.object.percento = UtilsCommon.computePercent(suma, hrubaSuma);
    //
    //     this.onChangeSumaBase(e.object);
    // }

    preratajSuma(object: XObject) {
        object.suma = this.vyratajSuma(object);
    }

    vyratajSuma(object: XObject): number {
        //object.suma = VydavokForm.preratajCezKurz(object.sumaVMene, object.kurz);
        // ratame vzdy sumovanim
        let sumaBL: number = 0;
        for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
            const vydavokBudgetLineSuma: number | null = numberFromModel(vydavokBudgetLine.suma);
            if (vydavokBudgetLineSuma !== null) {
                sumaBL += vydavokBudgetLineSuma;
            }
        }
        sumaBL = UtilsCommon.numberRound(sumaBL)!;
        return sumaBL;
    }

    // onChangeSumaBase(object: XObject) {
    //     // ak mame ciastkovu sumu a mame len 1 riadok v budget lines, tak vyplnime sumu v budget lines (aj ked uz bola vyplnena, nech to reaguje aj na zmenu percent)
    //     if (object.suma !== null && object.vydavokBudgetLineList.length === 1) {
    //         // sumu na budget line prepiseme vzdy (zatial)
    //         //const budgetLineSuma: number | null = numberFromModel(object.vydavokBudgetLineList[0].suma);
    //         //if (budgetLineSuma === null) {
    //         object.vydavokBudgetLineList[0].suma = object.suma;
    //         //}
    //     }
    //
    //     // preratame aj percenta na badget lines
    //     for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
    //         const vydavokBudgetLineSuma: number | null = numberFromModel(vydavokBudgetLine.suma);
    //         vydavokBudgetLine.percento = UtilsCommon.computePercent(vydavokBudgetLineSuma, object.suma);
    //     }
    // }

    onChangeMena(e: XFieldChangeEvent) {
        this.menaZmenena(e.object);
    }

    menaZmenena(object: XObject) {
        if (VydavokForm.isHlavnaMena(object)) {
            object.kurz = 1.0000;
            this.kurzZmeneny(object);
        }
    }

    static isHlavnaMena(object: XObject): boolean {
        const mena: Mena | null = object.mena;
        return mena !== null && mena.kod === Utils.getCurrentProjekt()?.mena.kod;
    }

    // ********** kurz *************

    readOnlyKurz(object: XObject): boolean {
        return VydavokForm.isHlavnaMena(object);
    }

    onChangeKurz(e: XFieldChangeEvent) {
        this.kurzZmeneny(e.object);
    }

    kurzZmeneny(object: XObject) {
        // pre jednoduchost preratame celu tabulku
        this.preratajBLRiadky(object);
        // este hrubu sumu v eur
        this.preratajHrubaSuma(object);
    }

    // *********** BL ***********

    onChangeBLSumaVMene(e: XTableFieldChangeEvent) {
        this.blSumaVMeneZmenena(e.object, e.tableRow, true);
    }

    preratajBLPercento(object: XObject, tableRow: any) {
        const blSumaVMene: number | null = numberFromModel(tableRow.sumaVMene);
        const sumaVMene: number | null = numberFromModel(object.sumaVMene);
        tableRow.percento = UtilsCommon.computePercent(blSumaVMene, sumaVMene);
    }

    preratajBLHrubaSumaVMene(object: XObject, tableRow: any) {
        let hrubaSumaVMene: number | null = null;
        const percento: number | null = numberFromModel(object.percento);
        const sumaVMene: number | null = numberFromModel(tableRow.sumaVMene);
        if (percento !== null && percento !== 0 && sumaVMene !== null) {
            hrubaSumaVMene = UtilsCommon.numberRound(sumaVMene / (percento / 100));
        }
        tableRow.hrubaSumaVMene = hrubaSumaVMene;
    }

    preratajBLSuma(object: XObject, tableRow: any) {
        tableRow.suma = VydavokForm.preratajCezKurz(tableRow.sumaVMene, object.kurz);
    }

    preratajBLAk1Riadok(object: XObject) {
        // ak mame len 1 riadok BL, tak don nastavime hodnotu z sumaVMene
        if (object.sumaVMene !== null && object.vydavokBudgetLineList.length === 1) {
            object.vydavokBudgetLineList[0].sumaVMene = object.sumaVMene;
            this.blSumaVMeneZmenena(object, object.vydavokBudgetLineList[0], false);
        }
    }

    preratajBLRiadky(object: XObject) {
        // prerata vsetky readOnly sumy na BL riadkoch
        // volane ak sa zmenila napr. sumaVMene (ovplyvnuje stlpec percento), percento (ovplyvnuje full-time info), kurz (ovplyvnuje sumu v eur)
        // pre jednoduchost preratavame naraz vsetko
        // !!! sumaVMene uz musi byt spravne preratana, aby spravne vyratal stlpec percento !!!

        for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
            this.preratajBLPercento(object, vydavokBudgetLine); // sumaVMene musi uz mat spravnu hodnotu!
            this.preratajBLSuma(object, vydavokBudgetLine); // suma v eur
            this.preratajBLHrubaSumaVMene(object, vydavokBudgetLine); // full-time suma pri mzdach
        }
        this.preratajSuma(object); // rata sa ako SUM(BLSuma)
        // hrubaSuma - preratava sa hrubaSumaVMene * kurz
    }

    blSumaVMeneZmenena(object: XObject, tableRow: any, preratajSumuSucet: boolean) {
        this.preratajBLSuma(object, tableRow); // suma v eur
        this.preratajSuma(object); // rata sa ako SUM(BLSuma)
        if (preratajSumuSucet) {
            this.preratajSumaVMeneZBL(object);
        }
        this.preratajBLPercento(object, tableRow); // ratame az po vyratani sumaVMene
        this.preratajBLHrubaSumaVMene(object, tableRow); // treba ratat az po pripadnom preratani object.percento
    }

    static preratajCezKurz(sumaVMene: any, kurz: any): number | null {
        let sumaVHlavnejMene: number | null = null;
        const sumaVMeneNumber: number | null = numberFromModel(sumaVMene);
        const kurzNumber: number | null = numberFromModel(kurz);
        if (sumaVMeneNumber !== null && kurzNumber !== null && kurzNumber !== 0) {
            sumaVHlavnejMene = UtilsCommon.numberRound(sumaVMeneNumber / kurzNumber, 2);
        }
        return sumaVHlavnejMene;
    }

    nastavDefaultMenu(object: XObject) {
        if (object.mena === null && object.pobocka) {
            const pobockaMenaList: PobockaMena[] = object.pobocka.pobockaMenaList;
            if (pobockaMenaList) {
                if (pobockaMenaList.length > 0) {
                    const pobockaMenaPredplnitList: PobockaMena[] = pobockaMenaList.filter((pobockaMena: PobockaMena) => pobockaMena.predplnit);
                    if (pobockaMenaPredplnitList.length === 1) {
                        object.mena = pobockaMenaPredplnitList[0].mena;
                    }
                }
                else {
                    // pobocka, ktora nepouziva menu
                    // ako menu nastavime hlavnu menu projektu (zvycajne EUR) a kurz dame 1.0000
                    object.mena = Utils.getCurrentProjekt()?.mena;
                    this.menaZmenena(object); // tu sa nastavi kurz 1.0000 a prepocitaju sa eur hodnoty
                }
            }
        }
    }


    // budgetLineDisplay(budgetLine: any): string {
    //     return budgetLine.kod + " - " + budgetLine.nazov;
    // }

    // overrides method in XFormBase
    async validate(object: XObject): Promise<XErrors> {
        const errors: XErrors = {};

        // ak mame na projekte zadany atribut uzatvoritPoMesiac, tak skontrolujeme ci datumVystavenia nebol zmeneny tak ze padne do uzatvoreneho obdobia
        const uzatvoritPoMesiac: Date | null = dateFromModel(Utils.getCurrentProjekt()!.uzatvoritPoMesiac);
        if (uzatvoritPoMesiac !== null) {
            const datumVystavenia: Date | null = dateFromModel(object.datumVystavenia);
            if (datumVystavenia !== null && !XUtilsCommon.dateEquals(datumVystavenia, this.datumVystaveniaFromEditStart)) {
                // datumVystavenia je vyplneny a bol zmeneny
                if (UtilsCommon.dateAsMonth(datumVystavenia)! <= uzatvoritPoMesiac) {
                    errors.datumVystavenia = `Nie je dovolené pridávať/presúvať výdavok do uzatvoreného obdobia - dátum dokladu ${dateAsUI(datumVystavenia)} musí byť väčší ako mesiac uzatvorenia ${dateAsUI(uzatvoritPoMesiac, XDateScale.Month)}.`;
                }
            }
        }

        // kontrola, ci sedi trojicka (hrubaSumaVMene, percento, sumaVMene)
        // trojicka sa da (automaticky) vyratat tromi sposobmi (jedna z troch sum na zaklade zvysnych dvoch)
        // netrufam si posudit ci plati, ze ak sa trojicka vyrata sposobom A, tak rovnost plati aj pre vysledky cez zvysne dva sposoby B a C
        // preto preratam cez vsetky 3 sposoby a ak jeden z nich sedi, tak je validacia OK (nepotrebujeme mat super presne hodnoty)
        const hrubaSumaVMene: number | null = numberFromModel(object.hrubaSumaVMene);
        const percento: number | null = numberFromModel(object.percento);
        const sumaVMene: number | null = numberFromModel(object.sumaVMene);

        if (hrubaSumaVMene !== null && percento !== null && sumaVMene !== null) {
            const sumaVMeneZPercenta: number | null = this.vyratajSumaVMeneZPercenta(object);
            if (sumaVMeneZPercenta !== sumaVMene) {
                // skusime dalsi sposob
                const percentoVyratane: number | null = this.vyratajPercento(object);
                if (percentoVyratane !== percento) {
                    // skusime este dalsi sposob
                    const hrubaSumaVMeneVyratana: number | null = this.vyratajHrubaSumaVMene(object);
                    if (hrubaSumaVMeneVyratana !== hrubaSumaVMene) {
                        errors.sumaVMene = `Položka "Suma %" (${numberAsUI(sumaVMene)}) sa nerovná vyrátanej hodnote (${numberAsUI(sumaVMeneZPercenta)}) na základe položiek "Celková suma" (${numberAsUI(hrubaSumaVMene)}) a "%" (${numberAsUI(percento)}).`;
                    }
                }
            }
        }

        // kontrola, ci sedi sucet poloziek BL
        if (sumaVMene !== null) {
            const sumaVMeneZBL: number = this.vyratajSumaVMeneZBL(object);
            // obidve sumy su zaokruhlene na 2 des. miesta, netreba pouzivat UtilsCommon.numberCompare
            if (sumaVMeneZBL !== sumaVMene) {
                const chybaSucetBL: string = `Súčet položiek budget lines (${numberAsUI(sumaVMeneZBL)}) sa nerovná položke "Suma %" (${numberAsUI(sumaVMene)}).`;
                // maly hack - ak je volny atribut sumaVMene, dame hlasku do neho (nech vycerveni sumaVMene), inac dame do chybaSucetBL, aby nedoslo k prepisaniu
                if (!errors.sumaVMene) {
                    errors.sumaVMene = chybaSucetBL;
                }
                else {
                    errors.chybaSucetBL = chybaSucetBL;
                }
            }
        }

        // tato chyba by nikdy nemala nastat ale pre istotu skontrolujeme aj sucet sum v eur
        const suma: number | null = numberFromModel(object.suma);
        const sumaVyratana: number = this.vyratajSuma(object);
        if (sumaVyratana !== suma) {
            errors.suma = `Neočakávaná chyba: Súčet položiek budget lines v hlavnej mene (${numberAsUI(sumaVyratana)}) sa nerovná položke "Suma %" v hlavnej mene (${numberAsUI(suma)}).`;
        }

        // kontrola - na 0-ovy budget dovolime zapisat len 0-ovu sumu
        for (const vydavokBudgetLine of object.vydavokBudgetLineList) {
            const blSumaVMene: number | null = numberFromModel(vydavokBudgetLine.sumaVMene);
            if (blSumaVMene !== null && this.jeBLNulovy(object.pobocka, vydavokBudgetLine.budgetLine)) {
                if (UtilsCommon.numberCompare(blSumaVMene, 0) === 1) { // blSumaVMene > 0
                    //zapiseme chyby do zaznamu vydavokBudgetLine do specialneho technickeho atributu
                    XFormBase.saveErrorsIntoXRowTechData(vydavokBudgetLine, {sumaVMene: `Na nulový budget line ${vydavokBudgetLine.budgetLine.kod} (pre zadanú pobočku) je možné zapisovať len nulovú sumu.`});
                }
            }
        }

        // kontrola unique budget line
        // Spis chcel aby boli duplicitne budget liny dovolene - chce to vyuzivat na evidovanie (aby nemusel sumu rozpisovat do poznamky)
        // for (let i: number = 0; i < object.vydavokBudgetLineList.length; i++) {
        //     const budgetLine1: any = object.vydavokBudgetLineList[i].budgetLine;
        //     for (let j: number = i + 1; j < object.vydavokBudgetLineList.length; j++) {
        //         const budgetLine2: any = object.vydavokBudgetLineList[j].budgetLine;
        //         if (budgetLine1 && budgetLine2 && budgetLine1.id === budgetLine2.id) {
        //             errors.vydavokBudgetLineList = "Duplicitný budget line nie je dovolený.";
        //             break;
        //         }
        //     }
        // }

        return errors;
    }

    jeBLNulovy(pobocka: Pobocka, budgetLine: BudgetLine): boolean {
        let nulovy: boolean = false;
        if (pobocka && budgetLine) {
            // ak mame koncovy spolocny budget = 0, alebo koncovy pobocky v sucte = 0, tak je nulovy
            if (UtilsCommon.numberCompare(budgetLine.budget, 0) === 0) {
                nulovy = true;
            }
            else {
                // budget pre vsetky pobocky spolu je > 0, skontrolujeme vybratu pobocku, ci nema nulovy budget
                const budgetLinePobockaList: BudgetLinePobocka[] = budgetLine.budgetLinePobockaList.filter((value: BudgetLinePobocka) => value.pobocka.id === pobocka.id);
                if (budgetLinePobockaList.length > 0 && UtilsCommon.numberCompare(budgetLinePobockaList[0].budget, 0) === 0) {
                    nulovy = true;
                }
            }
        }
        return nulovy;
    }

    render() {
        const pobockaStrediskoList: PobockaStredisko[] = this.state.object?.pobocka?.pobockaStrediskoList;
        const pobockaMenaList: PobockaMena[] = this.state.object?.pobocka?.pobockaMenaList;
        const sumyVMene: boolean = pobockaMenaList?.length > 0;
        const hlavnaMenaKod: string = sumyVMene ? ` ${Utils.getCurrentProjekt()?.mena.kod}` : "";

        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="projekt.nazov" label="Projekt" size={20}/>
                        <XAutoComplete form={this} assocField="pobocka" displayField="nazov" label="Pobočka" suggestions={this.state.pobockaList} readOnly={this.readOnlyPobocka} onChange={this.onChangePobocka} width="12.5rem"/>
                        <XAutoComplete form={this} assocField="stavVydavku" displayField="name" label="Stav" sortField="id" width="8rem"
                                   filter={!Utils.userMaPristup(this.state.object?.pobocka, TypPristupuEnum.AdminPobocky) ? {where: `[id] IN (${StavVydavkuEnum.Vytvoreny.id}, ${StavVydavkuEnum.Kompletny.id})`, params: {}} : undefined}/>
                    </div>
                    <div className="x-form-col">
                        <div className="x-form-row" style={{justifyContent: 'space-between'}}>
                            <div className="x-form-col">
                                <XAutoComplete form={this} assocField="typVydavku" displayField="name" label="Typ výdavku" sortField="id" labelStyle={{width:'11rem'}} width="11rem"/>
                                <XInputDate form={this} field="datumVystavenia" label="Dátum dokladu" labelStyle={{width:'11rem'}}/>
                                <XInputDate form={this} field="datumSplatnosti" label="Dátum platby" labelStyle={{width:'11rem'}}/>
                                {pobockaStrediskoList?.length > 0 ? <XAutoComplete form={this} assocField="stredisko" displayField="nazov" label="Stredisko" suggestions={pobockaStrediskoList} sortField="poradie" labelStyle={{width:'11rem'}} width="22rem"/> : null}
                                <XInputText form={this} field="cisloFaktury" label="Číslo dokladu" size={20} labelStyle={{width:'11rem'}}/>
                                <XInputText form={this} field="strucnyPopis" label="Popis výdavku" labelStyle={{width:'11rem'}} inputStyle={{width:'22rem'}}/>
                                <XInputText form={this} field="dodavatel" label="Dodávateľ" labelStyle={{width:'11rem'}} inputStyle={{width:'22rem'}}/>
                            </div>
                            {/* poznamka: nedaju sa pouzit XFormCol a XFormInlineRow lebo neakceptuju hodnoty null (inputy pre eur sumy) */}
                            <div className="x-form-col">
                                {sumyVMene ?
                                    <div className="x-form-inline-row">
                                        <XAutoComplete form={this} assocField="mena" displayField="kod" label="Mena" suggestions={pobockaMenaList.map((pobockaMena: PobockaMena) => pobockaMena.mena)} onChange={this.onChangeMena} width="6.928rem" labelStyle={{width:'10rem'}}/>
                                        <div className="field grid">
                                            <div className="col-fixed" style={{width: '1.428rem'}}/>
                                        </div>
                                        <div className="field grid">
                                            <InputText value={hlavnaMenaKod} readOnly={true} size={8}/>
                                        </div>
                                    </div>
                                    : null}
                                {sumyVMene ? <XInputDecimal form={this} field="kurz" label="Kurz" readOnly={this.readOnlyKurz} onChange={this.onChangeKurz} size={8} labelStyle={{width:'10rem'}}/> : null}
                                <div className="x-form-inline-row">
                                    <XInputDecimal form={this} field="hrubaSumaVMene" label="Celková suma" onChange={this.onChangeHrubaSumaVMene} size={8} labelStyle={{width:'10rem'}}/>
                                    <XCheckbox form={this} field="hrubaSumaVMeneFix" onChange={this.onChangeHrubaSumaVMeneFix} tooltip='Zafixovať voči zmene položiek "%" a "Suma %"'/>
                                    {sumyVMene ? <XInputDecimal form={this} field="hrubaSuma" size={8} readOnly={true}/> : null}
                                </div>
                                <div className="x-form-inline-row">
                                    <XInputDecimal form={this} field="percento" label="%" onChange={this.onChangePercento} size={8} labelStyle={{width:'10rem'}}/>
                                    <XCheckbox form={this} field="percentoFix" onChange={this.onChangePercentoFix} tooltip='Zafixovať voči zmene položiek "Celková suma" a "Suma %"'/>
                                </div>
                                <div className="x-form-inline-row">
                                    <XInputDecimal form={this} field="sumaVMene" label="Suma %" onChange={this.onChangeSumaVMene} size={8} labelStyle={{width:'10rem'}}/>
                                    <div className="field grid flex-column flex-nowrap" style={{height: '2.5rem'}}>
                                        <XCheckbox form={this} field="sumaVMeneFixZPercenta" onChange={this.onChangeSumaVMeneFixZPercenta} onlyInput={true} tooltip='Zafixovať voči zmene položiek "Celková suma" a "%"'/>
                                        <XCheckbox form={this} field="sumaVMeneFixZBL" onChange={this.onChangeSumaVMeneFixZBL} onlyInput={true} tooltip='Zafixovať voči zmene položiek budget line'/>
                                    </div>
                                    {sumyVMene ? <XInputDecimal form={this} field="suma" size={8} readOnly={true}/> : null}
                                </div>
                            </div>
                        </div>
                        <XFormDataTable2 form={this} assocField="vydavokBudgetLineList" label="Položky budget line">
                            <XFormColumn field="percento" header="%" readOnly={true} width="4.5rem"/>
                            <XFormColumn field="sumaVMene" header={sumyVMene ? `V mene` : `Suma`} width="7rem" onChange={this.onChangeBLSumaVMene}/>
                            <XFormColumn field="suma" header={`Suma${hlavnaMenaKod}`} width="7rem" columnViewStatus={sumyVMene ? XViewStatus.ReadOnly : XViewStatus.Hidden}/>
                            {/* vypocitavany readonly atribut hrubaSumaVMene (nema stlpec v DB) vytvoreny cez custom column (nie moc pekne riesenie) */}
                            <XFormCustomColumn field="hrubaSumaVMene" header="Celková suma" width="7rem" columnViewStatus={this.state.object?.typVydavku?.code === TypVydavkuEnum.Mzda.code ? XViewStatus.ReadOnly : XViewStatus.Hidden}
                                body={(tableRow: any, options: ColumnBodyOptions) => <XInputDecimalBase value={tableRow.hrubaSumaVMene} onChange={(value: number | null) => {tableRow.hrubaSumaVMene = value;}} readOnly={true}
                                                                                                        useGrouping={true} fractionDigits={2} className="x-input-to-resize"/>}/>
                            <XFormAutoCompleteColumn assocField="budgetLine" displayField="kodNazov" header="Budget line" width="40rem" suggestions={this.state.budgetLineList}/>
                        </XFormDataTable2>
                    </div>
                    <div className="x-form-col">
                        <XInputTextarea form={this} field="popis" label="Poznámky/upresnenie" labelOnTop rows={3} cols="full" autoResize={true} inputStyle={{minWidth:'25rem'}}/>
                        <XInputFileList2 form={this} assocField="vydavokPrilohaList" label="Prílohy" subdir={'p-' + Utils.getCurrentProjektId()} maxFileSize={100000000} /* 100 MB *//>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}

XUtils.registerAppForm(<VydavokForm/>, "Vydavok");
