import React, {useEffect, useState} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Utils} from "../Utils";
import {TypBudgetLineEnum, TypBudgetLineId} from "../common/enums";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {FilterMatchMode} from "primereact/api";
import {XFilterElementParams} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {DataTableFilterMetaData} from "primereact/datatable";
import {Button} from "primereact/button";

type BLFilterItemType = DataTableFilterMetaData & {valueUI: any[];};

// pomocna funkcia
export async function createFilterItemForBudgetLine(valueBudgetLineList: any[]): Promise<BLFilterItemType> {

    const idList: number[] = [];
    let whereKodLike: string = "";
    for (const budgetLine of valueBudgetLineList) {
        if (budgetLine !== null) {
            if (budgetLine.typBudgetLine.code === TypBudgetLineEnum.KoncovySpolocny || budgetLine.typBudgetLine.code === TypBudgetLineEnum.KoncovyPobocky) {
                idList.push(budgetLine.id);
            } else if (budgetLine.typBudgetLine.code === TypBudgetLineEnum.Suctovy) {
                // najdeme vsetky poduzly pre budget line "object" typu koncovy
                if (whereKodLike !== "") {
                    whereKodLike += " OR ";
                }
                whereKodLike += `[kod] LIKE '${budgetLine.kod}.%'`;
            } else {
                throw `Unexpected typBudgetLine = ${JSON.stringify(budgetLine.typBudgetLine)}`;
            }
        }
    }

    if (whereKodLike !== "") {
        let budgetLineList: any[] = await XUtils.fetchRows('BudgetLine', {
            where: `[projekt] = :projektId AND (${whereKodLike}) AND [typBudgetLine] IN (${TypBudgetLineId.KoncovySpolocnyId}, ${TypBudgetLineId.KoncovyPobockyId})`,
            params: {"projektId": Utils.getCurrentProjektId()}
        });
        const idListForKod: number[] = budgetLineList.map<number>((budgetLine: any) => budgetLine.id);
        idList.push(...idListForKod);
    }

    // odlozime si aj "budgetLineList" do specialnej valueUI (drobny hack), aby sme ho mali k dispozicii pri renderovani komponentu
    let filterItem: BLFilterItemType;
    if (idList.length > 0) {
        filterItem = {value: idList, matchMode: FilterMatchMode.IN, valueUI: valueBudgetLineList};
    }
    else {
        // nevyplnena hodnota vo filtri
        filterItem = {value: null, matchMode: FilterMatchMode.EQUALS, valueUI: valueBudgetLineList};
    }
    return filterItem;
}

export const VydavokBrowseBudgetLineFilter = (props: {filterParams: XFilterElementParams;}) => {

    const [budgetLineList, setBudgetLineList] = useState<any[]>([]);

    // valueBudgetLineList (uzivatelom vybrane hodnoty) je vytiahnuty vyssie do lazy tabulky
    // dovod je ten ze ho modifikuje lazy tabulka napr. ak user stlaci clearFilter alebo prichadza z hora z BudgetReport ak user stlaci "lupu"

    // precitame valueBudgetLineList (uzivatelom vybrane hodnoty) z filtra lazy tabulky
    const getValueBudgetLineList = (): any[] => {
        const filterItem: BLFilterItemType = props.filterParams.getFilterItem("vydavokBudgetLineList.budgetLine") as BLFilterItemType;
        return (filterItem && filterItem.valueUI !== undefined ? filterItem.valueUI : [null]);
    }

    // setneme valueBudgetLineList (uzivatelom vybrane hodnoty) do filtra lazy tabulky
    const setValueBudgetLineList = async (valueBudgetLineList: any[]) => {
        let filterItem: BLFilterItemType = await createFilterItemForBudgetLine(valueBudgetLineList);
        // poznamka: na backende bude v SQL field "vydavokBudgetLineList.budgetLine" nahradeny FK stlpcom (robi TypeORM)
        props.filterParams.setFilterItem("vydavokBudgetLineList.budgetLine", filterItem as DataTableFilterMetaData);
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let budgetLineList: any[] = await XUtils.fetchRows('BudgetLine', {where: `[projekt] = :projektId`, params: {"projektId": Utils.getCurrentProjektId()}}, 'kodNazov', ['typBudgetLine.id']);
        // pridame prazdnu polozku, aby sa userovi lahsie setoval null
        budgetLineList.splice(0, 0, {}); // null polozka
        setBudgetLineList(budgetLineList);
    }

    const onChangeBudgetLine = (valueBudgetLineList: any[], index: number, valueBudgetLine: any) => {
        // specialna null polozka nema ziadne atributy
        if (valueBudgetLine !== null && Object.keys(valueBudgetLine).length === 0) {
            valueBudgetLine = null;
        }
        // upravime valueBudgetLineList
        valueBudgetLineList[index] = valueBudgetLine;
        // setneme upraveny valueBudgetLineList do filtra lazy tabulky
        setValueBudgetLineList(valueBudgetLineList);
    }

    const removeBudgetLine = (valueBudgetLineList: any[], index: number) => {
        // upravime valueBudgetLineList
        valueBudgetLineList.splice(index, 1);
        // setneme upraveny valueBudgetLineList do filtra lazy tabulky
        setValueBudgetLineList(valueBudgetLineList);
    }

    const addBudgetLine = (valueBudgetLineList: any[]) => {
        // upravime valueBudgetLineList
        valueBudgetLineList.push(null);
        // setneme upraveny valueBudgetLineList do filtra lazy tabulky
        setValueBudgetLineList(valueBudgetLineList);
    }

    const onErrorChange = (error: string | undefined) => {
        //this.errorInBase = error; // odlozime si error
        // mohli by sme setnut do filtra null, ak error !== undefined, potom by pri chybnom zadani nevalidnej polozky zobrazil vsetky zaznamy
    }

    const valueBudgetLineList: any[] = getValueBudgetLineList();

    let elemList: JSX.Element[] = [];
    for (const [index, valueBudgetLine] of valueBudgetLineList.entries()) {
        elemList.push(
            <div key={index} className="flex flex-row flex-nowrap">
                <XAutoCompleteBase suggestions={budgetLineList} field="kodNazov" width="100%"
                                   value={valueBudgetLine} onChange={(object: any, objectChange: OperationType) => onChangeBudgetLine(valueBudgetLineList, index, object)}
                                   onErrorChange={onErrorChange}/>
                {valueBudgetLineList.length > 1 ? <Button icon="pi pi-times" onClick={() => removeBudgetLine(valueBudgetLineList, index)} className='x-button-icon-narrow ml-1'/> : null}
                {index === valueBudgetLineList.length - 1 ? <Button icon="pi pi-plus" onClick={() => addBudgetLine(valueBudgetLineList)} className='x-button-icon-narrow ml-1'/> : <div className='ml-1' style={{width:'1.5rem'}}/>}
            </div>
        );
    }

    return <div>{elemList}</div>;
}
