import React, {useEffect, useState} from "react";
import {Projekt} from "../model/projekt.entity";
import {Utils} from "../Utils";
import {dateAsUI, dateFromModel, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {UtilsCommon} from "../common/UtilsCommon";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {TypObdobiaEnum, XEnum} from "../common/enums";
import {Pobocka} from "../model/pobocka.entity";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {Button} from "primereact/button";

export interface FilterValues {
    selectedPobockaList: Pobocka[];
    typObdobia: XEnum;
    datumOd: Date | null;
    datumDo: Date | null;
    selectedDateList: DateOption[];
}

export interface DateOption {
    date: Date; // mesiac, prip. rok
    dateUI: string; // datum zobrazeny uzivatelovi
}

// sem sme vytiahli filter iba koli tomu ze bol strasne pomaly ak report obsahoval vela riadkov
export const BudgetReportFilter = (props: {
    pobockaSuggestionList: Pobocka[];
    initFilterValues: FilterValues;
    filtrovat: (filterValues: FilterValues) => void;
}) => {

    const [typObdobia, setTypObdobia] = useState<XEnum>(props.initFilterValues.typObdobia);
    const [datumOd, setDatumOd] = useState<Date | null>(props.initFilterValues.datumOd);
    const [datumDo, setDatumDo] = useState<Date | null>(props.initFilterValues.datumDo);
    const [selectedDateList, setSelectedDateList] = useState<DateOption[]>(props.initFilterValues.selectedDateList); // vyselectovane mesiace/roky
    const [selectedPobockaList, setSelectedPobockaList] = useState<Pobocka[]>(props.initFilterValues.selectedPobockaList); // vyselectovane pobocky

    // nechame "hore"
    //const [pobockaSuggestionList, setPobockaSuggestionList] = useState<Pobocka[]>([]);

    // staci takto do "member premennej", kedze nepotrebujeme zoznam menit, resp. nacitavat z DB
    // UPOZORNENIE: problem "membrov" je ze po zavolani akehokolvek set<XYZ>(<hodnota>)
    // sa spusti cela funkcia BudgetReport (koli render) odhora az nadol a "membre" sa nanovo nainicializuju
    // zatial iba mesacne obdobie, mozno neskor pridame aj dalsie
    const typObdobiaSuggestionList: XEnum[] = [TypObdobiaEnum.Datumy, TypObdobiaEnum.Mesacne/*, TypObdobiaEnum.Stvrtrocne, TypObdobiaEnum.Polrocne, TypObdobiaEnum.Rocne*/];

    const [optionsMesiace, setOptionsMesiace] = useState<DateOption[]>([]);

    useEffect(() => {
        loadOptionsMesiace();
    }, []);

    const loadOptionsMesiace = () => {
        // nacitame mesiace pre aktualny projekt, maximalne po aktualny mesiac (predpokladame ze v buducich mesiacoch este nie su ziadne vydavky)
        const currentProjekt: Projekt | null = Utils.getCurrentProjekt();
        if (!currentProjekt) {
            return []; // nemalo by nastat
        }

        const mesiacOd: Date = dateFromModel(currentProjekt.mesiacOd)!;
        let mesiacDo: Date = dateFromModel(currentProjekt.mesiacDo)!;
        const currentMonth: Date = UtilsCommon.currentMonth();
        if (mesiacDo > currentMonth) {
            mesiacDo = currentMonth;
        }
        const optionsMesiace: DateOption[] = [];
        for (let mesiac: Date = mesiacDo; mesiac >= mesiacOd; mesiac = XUtilsCommon.dateAddMonths(mesiac, -1)!) {
            optionsMesiace.push({date: mesiac, dateUI: dateAsUI(mesiac, XDateScale.Month)});
        }

        setOptionsMesiace(optionsMesiace);
    }

    return (
        <div className="flex justify-content-center m-1">
            <label className="m-2">Pobočky</label>
            <MultiSelect options={props.pobockaSuggestionList} optionLabel="nazov" maxSelectedLabels={5} display="chip" className="mr-2"
                         value={selectedPobockaList} onChange={(e: MultiSelectChangeEvent) => setSelectedPobockaList(e.value)}/>
            <Dropdown options={typObdobiaSuggestionList} optionLabel="name"
                      value={typObdobia} onChange={(e: any) => setTypObdobia(e.value)}/>
            {typObdobia === TypObdobiaEnum.Datumy ?
                [<label className="m-2">Dátum dokladu od</label>,
                    <XCalendar value={datumOd} onChange={(value: Date | null) => setDatumOd(value)}/>,
                    <label className="m-2">do</label>,
                    <XCalendar value={datumDo} onChange={(value: Date | null) => setDatumDo(value)}/>] : null
            }
            {typObdobia === TypObdobiaEnum.Mesacne ?
                <MultiSelect options={optionsMesiace} optionLabel="dateUI" maxSelectedLabels={5} display="chip" className="ml-2"
                             value={selectedDateList} onChange={(e: MultiSelectChangeEvent) => setSelectedDateList(e.value)}/> : null
            }
            <Button label="Filtrovať (DB)" onClick={() => props.filtrovat({
                selectedPobockaList: selectedPobockaList,
                typObdobia: typObdobia,
                datumOd: datumOd,
                datumDo: datumDo,
                selectedDateList: selectedDateList
            })} className="ml-2"/>
        </div>
    );
}