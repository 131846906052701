import React, {Component} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Utils} from "../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {Organizacia} from "../model/organizacia.entity";
import {XUserSkch} from "../model/x-user-skch.entity";

export interface OrganizaciaDropdownProps {
    onChangeOrganizacia: (organizacia: any) => void;
}

export class OrganizaciaDropdown extends Component<OrganizaciaDropdownProps> {

    state: {
        options: Organizacia[];
        currentOrganizacia: Organizacia | null;
    };

    constructor(props: OrganizaciaDropdownProps) {
        super(props);

        this.state = {
            options: [],
            currentOrganizacia: null
        };

        this.zmenaEntityOrganizacia = this.zmenaEntityOrganizacia.bind(this);
    }

    onChangeOrganizacia(organizacia: Organizacia) {
        this.setState({currentOrganizacia: organizacia});
        Utils.setCurrentOrganizacia(organizacia);
        if (!Utils.isUserAdmin()) {
            // ak nie je admin, tak nastavime zaznam xUserSkch zodpovedajuci aktualnej organizacii
            // mali by sme dostat prave 1 zaznam
            const xUserSkchList = Utils.getCurrentXUserSkchList().filter((xUserSkch: XUserSkch) => xUserSkch.organizacia.id === organizacia.id);
            if (xUserSkchList.length !== 1) {
                throw `Unexpected error: exactly 1 row x_user shoud exists for organisation ${organizacia.nazov}. Row count: ${xUserSkchList.length}`;
            }
            // user z XToken-u sa pouziva v preSave na setnutie vytvoril_id a pri zistovani prav cez Utils.is<Rola>
            XUtils.setXToken({...XUtils.getXToken(), xUser: xUserSkchList[0]});
        }
        // refreshneme aktualny obsah
        this.props.onChangeOrganizacia(organizacia);
    }

    componentDidMount() {
        this.findOptions();
        // zapiseme listener na zmenu do statickej premennej v Utils - listener sa zavola ak user spravi nejaku DB operaciu nad nejakym zaznamom Projekt
        Utils.setZmenaEntityOrganizacia(this.zmenaEntityOrganizacia);
    }

    private async findOptions() {
        let options: Organizacia[];
        if (Utils.isUserAdmin()) {
            // admin vidi vsetky organizacie
            options = await XUtils.fetchRows('Organizacia', undefined, 'nazov');
        }
        else {
            // dame len tie organizacie, v ktorych ma user svoj zaznam
            options = Utils.getCurrentXUserSkchList().map((xUserSkch: XUserSkch) => xUserSkch.organizacia);
        }

        this.setState({options: options});
        if (options.length === 1) {
            this.onChangeOrganizacia(options[0]);
        }
    }

    async zmenaEntityOrganizacia(object: XObject | null, objectChange: OperationType) {
        if (objectChange !== OperationType.None) {
            // zmenili sa data v DB -> refresneme
            await this.findOptions();
            // ak bol zmeneny aktualny projekt, zapiseme ho do state.currentProjekt
            if (objectChange === OperationType.Update && (object && this.state.currentOrganizacia && object.id === this.state.currentOrganizacia.id)) {
                this.setState({currentOrganizacia: object});
                Utils.setCurrentOrganizacia(object as Organizacia); // pre istotu (je to ina instancia ako ta v state.options ale je aktualna)
            }
        }
    }

    render() {
        // appendTo={document.body} appenduje overlay panel na element body - eliminuje "skakanie" formularu na mobile pri kliknuti na dropdown
        return (
            <Dropdown appendTo={document.body} id='projektDropdown' optionLabel='nazov' dataKey='id' value={this.state.currentOrganizacia} options={this.state.options}
                      onChange={(e: DropdownChangeEvent) => this.onChangeOrganizacia(e.value)}/>
        );
    }
}