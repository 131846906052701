import {XBrowseProps, XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {MenaForm} from "./MenaForm";

export const MenaBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MenaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MenaForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="Mena" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        displayed={props.displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="kod" header="Kód" width="10rem"/>
            <XLazyColumn field="nazov" header="Názov" width="20rem"/>
        </XLazyDataTable>
    );
}
