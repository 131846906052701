import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {PobockaForm} from "./PobockaForm";
import {Utils} from "../Utils";

export const PobockaBrowse = (props: {}) => {

    const onAddRow = () => {

        if (Utils.getCurrentOrganizacia() === null) {
            alert("Vyberte najprv organizáciu.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<PobockaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<PobockaForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="Pobocka" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        customFilter={Utils.filterCurrentOrganizacia()}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="nazov" header="Názov" width="40rem"/>
        </XLazyDataTable>
    );
}
