import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export class UtilsCommon {

    static computePercent(partSum: number | null, baseSum: number | null): number | null {
        let percent: number | null = null;
        if (partSum !== null && baseSum !== null) {
            if (baseSum !== 0) {
                const percentNotRounded: number = partSum / baseSum * 100;
                percent = Math.round(percentNotRounded * 100) / 100;
            }
            else {
                // baseSum === 0
                if (partSum === 0) {
                    percent = 0;
                }
            }
        }
        return percent;
    }

    // pri scitavani number cisel som zaznamenal rozdiel v 14. cifre za des. ciarkou, na porovnavanie preto pouzijeme deltu 0.0000000001 (-10 na -10)
    // 1600.15 + 576.95 vracia 2177.10000000000004
    private static delta: number = 10 ** -10;

    static numberCompare(value1: number | null, value2: number | null): (-1 | 0 | 1) | null {
        let result: (-1 | 0 | 1) | null = null;
        if (value1 !== null && value2 !== null) {
            const difference: number = value1 - value2;
            if (Math.abs(difference) < UtilsCommon.delta) {
                result = 0;
            }
            else if (difference < 0) {
                result = -1;
            }
            else {
                // difference > 0
                result = 1;
            }
        }
        return result;
    }

    static numberRound(value: number | null, scale?: number): number | null {
        if (value !== null) {
            scale = scale ?? 2;
            const multiplier: number = 10 ** scale; // 100 for scale = 2
            value = Math.round(value * multiplier) / multiplier;
        }
        return value;
    }

    static currentMonth(): Date {
        return UtilsCommon.dateAsMonth(XUtilsCommon.today())!;
    }

    static dateAsMonth(date: Date | null): Date | null {
        let month: Date | null = null;
        if (date !== null) {
            month = new Date(date); // create copy not to change "date"
            month.setUTCDate(1); // first day of month
        }
        return month;
    }
}
