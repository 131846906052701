import React from "react";
import {Form} from "../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";

@Form("Organizacia")
export class OrganizaciaForm extends XFormBaseModif {

    render() {
        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputText form={this} field="nazov" label="Názov" size={50}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}

XUtils.registerAppForm(<OrganizaciaForm/>, "Organizacia");
