import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {BudgetLineForm} from "./BudgetLineForm";
import {Utils} from "../Utils";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";

export const BudgetLineBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        if (Utils.getCurrentProjekt() === null) {
            alert("Vyberte najprv projekt.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<BudgetLineForm initValues={{projekt: Utils.getCurrentProjekt(), parent: null, zmenitObdobieZProjektu: false, mesiacOd: null, mesiacDo: null, budgetLinePobockaList: [], version: 0}}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<BudgetLineForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> =>  {

        if (window.confirm('Are you sure to remove the selected row?')) {
            await XUtils.post('remove-budget-line', {id: selectedRow.id});
            return true;
        }
        return false;
    }


    return (
        <XLazyDataTable entity="BudgetLine" rows={30} sortField="kod" customFilter={{where: "[projekt] = :projektId", params: {"projektId": Utils.getCurrentProjektId()}}}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="kod" header="Kód"/>
            <XLazyColumn field="nazov" header="Názov" width="40rem"/>
            <XLazyColumn field="typBudgetLine.name" header="Typ" width="12.5rem" dropdownInFilter={true}/>
            <XLazyColumn field="budget" header="Budget" width="8rem"/>
        </XLazyDataTable>
    );
}
